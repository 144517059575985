import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  KeyValueDiffers,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import Panzoom from '@panzoom/panzoom';
import { PanzoomObject } from '@panzoom/panzoom/dist/src/types';
import { Helper } from 'app/common/helper';
import {
  AlignmentEnum,
  Constant,
  DelimitersEnum,
  DisplayModelEnum,
  EditTemplateToolsEnum,
  FIELD_COMPONENT,
  FontTypeEnum,
  LEDTemplateModeEnum,
  LinkDataPictureEnum,
  LinkDataPictureLEDEnum,
  LinkDataTextLEDEnum,
  MODULE_NAME,
  ObjectFitEnum,
  OrientationEnum,
  OUTLINE,
  ReferencePositionColumnEDSEnum,
  ScrollDirectionsEnum,
  ScrollStatusEnum,
  SpeedEnum,
  TypeMediaFileEnum,
  TypeResizeAreaEnum
} from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogTemplateGroupLedComponent } from 'app/dialog/led/dialog-template-group-led/dialog-template-group-led.component';
import { DialogTemplateLedComponent } from 'app/dialog/led/dialog-template-led/dialog-template-led.component';
import { TemplateGroupLED } from 'app/model/entity/destination/template-group-led';
import { TemplateLED } from 'app/model/entity/destination/template-led';
import { IndexWordGroup } from 'app/model/entity/index-word-group';
import { AreaLED } from 'app/model/entity/led/area-led';
import { PictureAreaLED } from 'app/model/entity/led/picture-area-led';
import { TextAreaLED } from 'app/model/entity/led/text-area-led';
import { ScaleEntity } from 'app/model/entity/scale';
import { SaveLEDEditorStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { FontService } from 'app/service/font.service';
import { IndexWordGroupService } from 'app/service/index-word-group.service';
import { AreaLEDService } from 'app/service/led/area-led.service';
import { DrawLEDService } from 'app/service/led/draw-led.service';
import { MediaService } from 'app/service/media.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TemplateGroupLedService } from 'app/service/template-group-led.service';
import { TemplateLedService } from 'app/service/template-led.service';
import { AppState } from 'app/store/app.state';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'app/service/common.service';
import { Common } from 'app/model/entity/common';
import { FontCharacterBitmap } from 'app/model/entity/led/font-character-bitmap';

@Component({
  selector: 'led-layout-editor',
  templateUrl: './led-layout-editor.component.html',
  styleUrls: ['./led-layout-editor.component.scss']
})
export class LedLayoutEditorComponent implements OnInit {
  /**
   * save data success
   */
  @Output() saveDataSuccess = new EventEmitter<boolean>();

  /**
   * subscriptions
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * constant
   */
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  PATH_SELECT_AREA = Constant.PATH_SELECT_AREA;
  PATH_CREATE_FIX_TEXT = Constant.PATH_CREATE_FIX_TEXT;
  PATH_CREATE_LINK_TEXT = Constant.PATH_CREATE_LINK_TEXT;
  PATH_CREATE_FIX_PICTURE = Constant.PATH_CREATE_FIX_PICTURE;
  PATH_CREATE_LINK_PICTURE = Constant.PATH_CREATE_LINK_PICTURE;
  PATH_ZOOM_PAN = Constant.PATH_ZOOM_PAN;
  PATH_ALIGNMENT = Constant.PATH_ALIGNMENT;
  PATH_ALIGN_LEFT = Constant.PATH_ALIGN_LEFT;
  PATH_ALIGN_CENTER = Constant.PATH_ALIGN_CENTER;
  PATH_ALIGN_RIGHT = Constant.PATH_ALIGN_RIGHT;
  PATH_ALIGN_TOP = Constant.PATH_ALIGN_TOP;
  PATH_ALIGN_MIDDLE = Constant.PATH_ALIGN_MIDDLE;
  PATH_ALIGN_BOTTOM = Constant.PATH_ALIGN_BOTTOM;
  PATH_ALIGN_DISTRIBUTE_HORIZONTAL = Constant.PATH_ALIGN_DISTRIBUTE_HORIZONTAL;
  PATH_ALIGN_DISTRIBUTE_VERTICAL = Constant.PATH_ALIGN_DISTRIBUTE_VERTICAL;
  LinkDataPictureLEDEnum = LinkDataPictureLEDEnum;
  LinkDataTextLEDEnum = LinkDataTextLEDEnum;
  ReferencePositionColumnEDSEnum = ReferencePositionColumnEDSEnum;
  ObjectFitEnum = ObjectFitEnum;
  OrientationEnum = OrientationEnum;
  ScrollStatusEnum = ScrollStatusEnum;
  AlignmentEnum = AlignmentEnum;
  DisplayModelEnum = DisplayModelEnum;
  SpeedEnum = SpeedEnum;
  ScrollDirectionsEnum = ScrollDirectionsEnum;
  DelimitersEnum = DelimitersEnum;
  ElementInputLED = ElementInputLED;
  EditTemplateToolsEnum = EditTemplateToolsEnum;
  LEDTemplateModeEnum = LEDTemplateModeEnum;
  OUTLINE = OUTLINE;
  Constant = Constant;
  FontTypeEnum = FontTypeEnum;
  readonly MINIMUM_SCALE_PREVIEW = 0.25;
  readonly MAXIMUM_SCALE_PREVIEW = 2;
  readonly MINIMUM_FONT_SIZE = 6;
  readonly MAXIMUM_FONT_SIZE = 72;
  readonly MINIMUM_W = 1;
  readonly MINIMUM_H = 1;
  readonly MINIMUM_X = 1;
  readonly MINIMUM_Y = 1;
  readonly MAXIMUM_TEXT = 15;
  readonly MINIMUM_PAUSE_TIME = 0;
  readonly MAXIMUM_PAUSE_TIME = 9;
  readonly MINIMUM_LEAD_SPACING = -10;
  readonly MAXIMUM_LEAD_SPACING = 10;
  readonly MINIMUM_LETTER_SPACING = -10;
  readonly MAXIMUM_LETTER_SPACING = 10;
  readonly MINIMUM_BASELINE_HEIGHT = -10;
  readonly MAXIMUM_BASELINE_HEIGHT = 10;
  readonly MINIMUM_DURATION = 1;
  readonly MAXIMUM_DURATION = 9;
  readonly MAX_LENGTH_NAME = 72;
  readonly EDIT_AREA_NAME_ID = 'editAreaName';
  readonly TEXTS_DEFAULT = ['AAA', 'BBB', 'CCC', 'DDD', 'EEE'];
  readonly PAUSE_TIME_DEFAULT = 5;
  readonly BORDER_COLOR_TEXT = '#00B050';
  readonly BORDER_COLOR_PICTURE = '#FF66FF';
  readonly BORDER_STYLE_FIX = 'solid';
  readonly BORDER_STYLE_LINK = 'dashed';
  readonly MAXIMUM_OTHER_DELIMITERS = 255;
  readonly MAX_LENGTH_AREA_NAME = 20;

  /**
   * divPreviewLED
   */
  @ViewChild('divPreviewLED', { static: false })
  divPreviewLED: ElementRef;
  /**
   * canvasLEDContainer
   */
  @ViewChild(Constant.CANVAS_LED_CONTAINER_ID, { static: false })
  canvasLEDContainer: ElementRef;
  /**
   * areaResize
   */
  areaResize: { area: AreaLED; cursor: string; type: TypeResizeAreaEnum };
  /**
   * common object
   */
  commonObject: Common;
  /**
   * true if show template detail
   */
  isShowedTemplateEditor: boolean;
  /**
   * template group selected
   */
  templateGroupSelected: TemplateGroupLED;
  /**
   * template groups
   */
  templateGroups: Array<TemplateGroupLED>;
  /**
   * template selected
   */
  templateSelected: TemplateLED;
  /**
   * templates of group
   */
  templatesOfGroup: Array<TemplateLED>;
  /**
   * templates
   */
  templates: Array<TemplateLED>;
  /**
   * true if changed data template
   */
  isChangedData: boolean;
  /**
   * template group mode filter
   */
  templateGroupModeFilter = LEDTemplateModeEnum.ALL;
  /**
   * template groups origin
   */
  templateGroupsOrigin: Array<TemplateGroupLED>;
  /**
   * index word groups
   */
  indexWordGroups: Array<IndexWordGroup>;
  /**
   * true if hide templates
   */
  isHiddenTemplates: boolean;
  /**
   * tool edit template selected
   */
  toolEditTemplateSelected: EditTemplateToolsEnum;
  /**
   * true if show align tool
   */
  isShowAlignTool: boolean;
  /**
   * scale preview
   */
  scalePreview: ScaleEntity = new ScaleEntity('100', 1);
  /**
   * true if clear all border canvas
   */
  isClearAllBorderCanvas: boolean;
  /**
   * areas selected
   */
  areasSelected: Array<AreaLED> = new Array<AreaLED>();
  /**
   * true if hide setting area
   */
  isHiddenSettingArea: boolean;
  /**
   * true if hide areas
   */
  isHiddenAreas: boolean;
  /**
   * areas
   */
  areas: Array<AreaLED> = new Array<AreaLED>();
  /**
   * area selected
   */
  areaSelected: AreaLED;
  /**
   * fonts bitmap
   */
  fontsBitmap: [];
  /**
   * font color
   */
  fontColor: any;
  /**
   * background color
   */
  backgroundColor: any;
  /**
   * outline color
   */
  outlineColor: any;
  /**
   * true if show color picker
   */
  isShowColorPicker: boolean;
  /**
   * panzoom
   */
  panzoom: PanzoomObject;
  /**
   * offset top current
   */
  offsetTopCurrent: number = 0;
  /**
   * offset left current
   */
  offsetLeftCurrent: number = 0;
  /**
   * end point
   */
  endPoint: any;
  /**
   * point drag start
   */
  pointDragStart: MouseEvent;
  /**
   * true if mouse down
   */
  isMouseDown: boolean;
  /**
   * point down first
   */
  pointDownFirst: MouseEvent;
  /**
   * point start X
   */
  pointStartX: any;
  /**
   * point start Y
   */
  pointStartY: any;
  /**
   * point down last
   */
  pointDownLast: any;
  /**
   * differ
   */
  differ: any;
  /**
   * font names bitmap
   */
  fontNamesBmp: Array<any>;
  /**
   * fonts display
   */
  fontsDisplay: Array<any>;
  /**
   * old template selected
   */
  oldTemplateSelected: TemplateLED;
  /**
   * old value X
   */
  oldValueX: number;
  /**
   * old value Y
   */
  oldValueY: number;
  /**
   * old value W
   */
  oldValueW: number;
  /**
   * old value H
   */
  oldValueH: number;
  /**
   * true if show area on preview
   */
  isShowAreasOnPreview: boolean = true;
  /**
   * true if data invalid
   */
  isInvalid: boolean;
  /**
   * true if edit text
   */
  isEditText: boolean;
  /**
   * stateOfComponent
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    isShowedTemplateEditor: boolean;
    templateGroupSelected: TemplateGroupLED;
    templateGroups: Array<TemplateGroupLED>;
    templateSelected: TemplateLED;
    templatesOfGroup: Array<TemplateLED>;
    templates: Array<TemplateLED>;
    isChangedData: boolean;
    templateGroupModeFilter: LEDTemplateModeEnum;
    templateGroupsOrigin: Array<TemplateGroupLED>;
    indexWordGroups: Array<IndexWordGroup>;
    isHiddenTemplates: boolean;
    toolEditTemplateSelected: EditTemplateToolsEnum;
    isShowAlignTool: boolean;
    scalePreview: ScaleEntity;
    isClearAllBorderCanvas: boolean;
    areasSelected: Array<AreaLED>;
    isHiddenSettingArea: boolean;
    isHiddenAreas: boolean;
    areas: Array<AreaLED>;
    areaSelected: AreaLED;
    fontsBitmap: [];
    fontColor: any;
    backgroundColor: any;
    outlineColor: any;
    fontNamesBmp: Array<any>;
    fontsDisplay: Array<any>;
    oldTemplateSelected: TemplateLED;
    isShowAreasOnPreview: boolean;
  };

  constructor(
    private dialogService: DialogService,
    private menuActionService: MenuActionService,
    private indexWordGroupService: IndexWordGroupService,
    private renderer: Renderer2,
    private dataService: DataService,
    private templateGroupLedService: TemplateGroupLedService,
    private templateLedService: TemplateLedService,
    private changeDetectorRef: ChangeDetectorRef,
    private fontService: FontService,
    private areaLEDService: AreaLEDService,
    private drawLEDService: DrawLEDService,
    private toast: ToastrService,
    public readonly store: Store<AppState>,
    private mediaService: MediaService,
    private differs: KeyValueDiffers,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    this.differ = this.differs.find({}).create();

    // add template group
    this.subscriptions.push(
      this.menuActionService.actionAddFolder.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LedLayoutEditorComponent]) {
          this.addTemplateGroup();
        }
      })
    );

    // add template
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LedLayoutEditorComponent]) {
          this.addTemplate();
        }
      })
    );

    // edit template group / template
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LedLayoutEditorComponent]) {
          if (!this.templateSelected) {
            this.editTemplateGroup();
          } else {
            this.editTemplate();
          }
        }
      })
    );

    // duplicate template
    this.subscriptions.push(
      this.menuActionService.actionDuplicate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LedLayoutEditorComponent]) {
          this.duplicateTemplate();
        }
      })
    );

    // delete template group / template
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LedLayoutEditorComponent]) {
          if (!this.templateSelected) {
            this.deleteTemplateGroup();
          } else {
            this.deleteTemplate();
          }
        }
      })
    );

    /**
     * exit layout template detail
     */
    this.subscriptions.push(
      this.menuActionService.actionExit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LedLayoutEditorComponent]) {
          if (this.isShowedTemplateEditor) {
            this.exitLayoutTemplate();
          }
        }
      })
    );

    /**
     * save areas
     */
    this.subscriptions.push(
      this.menuActionService.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LedLayoutEditorComponent]) {
          this.saveAreas();
        }
      })
    );

    /**
     * save as template
     */
    this.subscriptions.push(
      this.menuActionService.actionSaveAs.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LedLayoutEditorComponent]) {
          this.saveAsTemplate();
        }
      })
    );

    /**
     * get offset left
     */
    this.subscriptions.push(
      this.dataService.currentData.subscribe(data => {
        if (data[0] == 'offsetLeft') {
          this.offsetLeftCurrent = <number>data[1];
        }
      })
    );

    /**
     * get offset top
     */
    this.subscriptions.push(
      this.dataService.currentData.subscribe(data => {
        if (data[0] == 'offsetTop') {
          this.offsetTopCurrent = <number>data[1];
          if (this.offsetTopCurrent > 0) {
            this.offsetTopCurrent = this.offsetTopCurrent - 36;
          }
        }
      })
    );
    this.commonObject = commonService.getCommonObject();
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.ledLayoutEditorState?.stateOfComponent.isChangeLayout,
            isShowedTemplateEditor: componentState.ledLayoutEditorState?.stateOfComponent.isShowedTemplateEditor,
            templateGroupSelected: componentState.ledLayoutEditorState?.stateOfComponent.templateGroupSelected,
            templateGroups: componentState.ledLayoutEditorState?.stateOfComponent.templateGroups,
            templateSelected: componentState.ledLayoutEditorState?.stateOfComponent.templateSelected,
            templatesOfGroup: componentState.ledLayoutEditorState?.stateOfComponent.templatesOfGroup,
            templates: componentState.ledLayoutEditorState?.stateOfComponent.templates,
            isChangedData: componentState.ledLayoutEditorState?.stateOfComponent.isChangedData,
            templateGroupModeFilter: componentState.ledLayoutEditorState?.stateOfComponent.templateGroupModeFilter,
            templateGroupsOrigin: componentState.ledLayoutEditorState?.stateOfComponent.templateGroupsOrigin,
            indexWordGroups: componentState.ledLayoutEditorState?.stateOfComponent.indexWordGroups,
            isHiddenTemplates: componentState.ledLayoutEditorState?.stateOfComponent.isHiddenTemplates,
            toolEditTemplateSelected: componentState.ledLayoutEditorState?.stateOfComponent.toolEditTemplateSelected,
            isShowAlignTool: componentState.ledLayoutEditorState?.stateOfComponent.isShowAlignTool,
            scalePreview: componentState.ledLayoutEditorState?.stateOfComponent.scalePreview,
            isClearAllBorderCanvas: componentState.ledLayoutEditorState?.stateOfComponent.isClearAllBorderCanvas,
            areasSelected: componentState.ledLayoutEditorState?.stateOfComponent.areasSelected,
            isHiddenSettingArea: componentState.ledLayoutEditorState?.stateOfComponent.isHiddenSettingArea,
            isHiddenAreas: componentState.ledLayoutEditorState?.stateOfComponent.isHiddenAreas,
            areas: componentState.ledLayoutEditorState?.stateOfComponent.areas,
            areaSelected: componentState.ledLayoutEditorState?.stateOfComponent.areaSelected,
            fontsBitmap: componentState.ledLayoutEditorState?.stateOfComponent.fontsBitmap,
            fontColor: componentState.ledLayoutEditorState?.stateOfComponent.fontColor,
            backgroundColor: componentState.ledLayoutEditorState?.stateOfComponent.backgroundColor,
            outlineColor: componentState.ledLayoutEditorState?.stateOfComponent.outlineColor,
            fontNamesBmp: componentState.ledLayoutEditorState?.stateOfComponent.fontNamesBmp,
            fontsDisplay: componentState.ledLayoutEditorState?.stateOfComponent.fontsDisplay,
            oldTemplateSelected: componentState.ledLayoutEditorState?.stateOfComponent.oldTemplateSelected,
            isShowAreasOnPreview: componentState.ledLayoutEditorState?.stateOfComponent.isShowAreasOnPreview
          };
        })
    );
  }

  /**
   * compare template
   * @param templateFirst
   * @param templateSecond
   * @returns
   */
  private compareTemplate(templateFirst: any, templateSecond: any): boolean {
    if (!templateFirst || !templateSecond) {
      return true;
    }
    var tempFirst = _.cloneDeep(templateFirst);
    var tempSecond = _.cloneDeep(templateSecond);
    [tempFirst, tempSecond].forEach(temp => {
      if (temp.areas?.length > 0) {
        temp.areas.forEach(area => {
          delete area?.canvas;
          delete area.symbol;
          delete area.displayPosX;
          delete area.displayPosY;
          delete area.isSelected;
          delete area.isHidden;
          delete area.areaPosXs;
          delete area.fontSizesBitmap;
          delete area.areasLinkWith;
          delete area.isHideChangeOver;
        });
      }
    });
    return _.isEqual(tempFirst, tempSecond);
  }

  ngDoCheck() {
    if (!this.isShowedTemplateEditor) {
      return;
    }
    let empListChanges = this.differ.diff(this.templateSelected);
    if (empListChanges && this.templateSelected?.areas) {
      let empListChangesArea = this.differ.diff(this.templateSelected.areas);
      if (empListChangesArea) {
        this.isChangedData = !this.compareTemplate(this.templateSelected, this.oldTemplateSelected);
      }
    }
  }

  ngOnInit(): void {
    if (!this.stateOfComponent?.isChangeLayout) {
      this.getAllTemplateGroup();
    } else {
      this.handleAfterChangeLayout();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(
      new SaveLEDEditorStateAction({
        isChangeLayout: true,
        isShowedTemplateEditor: this.isShowedTemplateEditor,
        templateGroupSelected: this.templateGroupSelected,
        templateGroups: this.templateGroups,
        templateSelected: this.templateSelected,
        templatesOfGroup: this.templatesOfGroup,
        templates: this.templates,
        isChangedData: this.isChangedData,
        templateGroupModeFilter: this.templateGroupModeFilter,
        templateGroupsOrigin: this.templateGroupsOrigin,
        indexWordGroups: this.indexWordGroups,
        isHiddenTemplates: this.isHiddenTemplates,
        toolEditTemplateSelected: this.toolEditTemplateSelected,
        isShowAlignTool: this.isShowAlignTool,
        scalePreview: this.scalePreview,
        isClearAllBorderCanvas: this.isClearAllBorderCanvas,
        areasSelected: this.areasSelected,
        isHiddenSettingArea: this.isHiddenSettingArea,
        isHiddenAreas: this.isHiddenAreas,
        areas: this.areas,
        areaSelected: this.areaSelected,
        fontsBitmap: this.fontsBitmap,
        fontColor: this.fontColor,
        backgroundColor: this.backgroundColor,
        outlineColor: this.outlineColor,
        fontNamesBmp: this.fontNamesBmp,
        fontsDisplay: this.fontsDisplay,
        oldTemplateSelected: this.oldTemplateSelected,
        isShowAreasOnPreview: this.isShowAreasOnPreview
      })
    );
  }

  /**
   * handle after change layout
   */
  private handleAfterChangeLayout(): void {
    this.isShowedTemplateEditor = this.stateOfComponent.isShowedTemplateEditor;
    this.templateGroupSelected = this.stateOfComponent.templateGroupSelected;
    this.templateGroups = this.stateOfComponent.templateGroups;
    this.templateSelected = this.stateOfComponent.templateSelected;
    this.templatesOfGroup = this.stateOfComponent.templatesOfGroup;
    this.templates = this.stateOfComponent.templates;
    this.isChangedData = this.stateOfComponent.isChangedData;
    this.templateGroupsOrigin = this.stateOfComponent.templateGroupsOrigin;
    this.templateGroupModeFilter = this.stateOfComponent.templateGroupModeFilter;
    this.indexWordGroups = this.stateOfComponent.indexWordGroups;
    this.isHiddenTemplates = this.stateOfComponent.isHiddenTemplates;
    this.toolEditTemplateSelected = this.stateOfComponent.toolEditTemplateSelected;
    this.isShowAlignTool = this.stateOfComponent.isShowAlignTool;
    this.scalePreview = this.stateOfComponent.scalePreview;
    this.isClearAllBorderCanvas = this.stateOfComponent.isClearAllBorderCanvas;
    this.areasSelected = this.stateOfComponent.areasSelected;
    this.isHiddenSettingArea = this.stateOfComponent.isHiddenSettingArea;
    this.isHiddenAreas = this.stateOfComponent.isHiddenAreas;
    this.areas = this.stateOfComponent.areas;
    this.areaSelected = this.stateOfComponent.areaSelected;
    this.fontsBitmap = this.stateOfComponent.fontsBitmap;
    this.fontColor = this.stateOfComponent.fontColor;
    this.backgroundColor = this.stateOfComponent.backgroundColor;
    this.outlineColor = this.stateOfComponent.outlineColor;
    this.fontNamesBmp = this.stateOfComponent.fontNamesBmp;
    this.fontsDisplay = this.stateOfComponent.fontsDisplay;
    this.oldTemplateSelected = this.stateOfComponent.oldTemplateSelected;
    this.isShowAreasOnPreview = this.stateOfComponent.isShowAreasOnPreview;

    this.dataService.sendData([Constant.IS_SELECTED_TEMPLATE_GROUP, this.templateGroupSelected]);
    this.dataService.sendData([Constant.IS_SHOW_CANVAS_LED, this.isShowedTemplateEditor]);

    // draw template if show template detail
    if (this.isShowedTemplateEditor) {
      this.drawPreview();
      this.selectTool(this.toolEditTemplateSelected);
      if (this.areaSelected) {
        this.setBorderAreaSelected(this.areaSelected);
      }
    }
  }

  /**
   * get all template group
   */
  private getAllTemplateGroup(): void {
    this.templateGroupLedService.getAllTemplateGroup().subscribe(
      data => {
        this.templateGroupsOrigin = data;
        this.templateGroups = data;
      },
      () => {
        this.showDialogError();
        return;
      }
    );
  }

  /**
   * add template group
   */
  public addTemplateGroup(): void {
    // return if show layout template detail
    if (this.isShowedTemplateEditor) {
      return;
    }
    let templateGroupNew = new TemplateGroupLED(
      null,
      '',
      Constant.DEFAULT_TEMPLATE_LED_WIDTH,
      Constant.DEFAULT_TEMPLATE_LED_HEIGHT,
      '',
      LEDTemplateModeEnum.DESTINATION_SIGN,
      DisplayModelEnum.FULL_COLOR
    );
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogTemplateGroupLedComponent,
      {
        data: {
          title: Constant.TITLE_ADD_TEMPLATE_GROUP,
          templateGroup: Object.assign({}, templateGroupNew),
          templateGroups: this.templateGroupsOrigin
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          templateGroupNew = result;
          this.templateGroupLedService.addNewTemplateGroup(result).subscribe(
            data => {
              if (this.templateGroupModeFilter == data.templateMode) {
                this.templateGroups.push(data);
              }
              this.templateGroupsOrigin.push(data);
              this.templateGroupSelected = undefined;
              this.dataService.sendData([Constant.IS_SELECTED_TEMPLATE_GROUP, this.templateGroupSelected]);
            },
            () => {
              this.showDialogError();
            }
          );
        }
      }
    );
  }

  /**
   * edit template group
   * @returns
   */
  private editTemplateGroup(): void {
    // return if select template or show layout template detail
    if (this.templateSelected || this.isShowedTemplateEditor || !this.templateGroupSelected) {
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogTemplateGroupLedComponent,
      {
        data: {
          title: Constant.TITLE_EDIT_TEMPLATE_GROUP,
          templateGroup: Object.assign({}, this.templateGroupSelected),
          templateGroups: this.templateGroupsOrigin.filter(templateGroup => templateGroup.id != this.templateGroupSelected.id)
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          this.templateGroupLedService.editTemplateGroup(result).subscribe(
            data => {
              // changeTemplateGroup
              let selectedIndex = this.templateGroups.findIndex(templateGroup => templateGroup.id == this.templateGroupSelected.id);
              let selectedIndexOrigin = this.templateGroupsOrigin.findIndex(
                templateGroup => templateGroup.id == this.templateGroupSelected.id
              );
              this.templateGroups[selectedIndex].name = data.name;
              this.templateGroupsOrigin[selectedIndexOrigin].name = data.name;
              this.templateGroupSelected = undefined;
              this.dataService.sendData([Constant.IS_SELECTED_TEMPLATE_GROUP, this.templateGroupSelected]);
              this.saveDataSuccess.emit(true);
            },
            () => {
              this.showDialogError();
              return;
            }
          );
        }
      }
    );
  }

  /**
   * add template
   */
  public addTemplate(): void {
    // return if show layout template detail
    if (this.isShowedTemplateEditor || !this.templateGroupSelected) {
      return;
    }
    let templateNew = new TemplateLED(
      '',
      this.templateGroupSelected.width,
      this.templateGroupSelected.height,
      this.templateGroupSelected.id,
      this.templateGroupSelected.name
    );
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogTemplateLedComponent,
      {
        data: {
          title: Constant.TITLE_ADD_TEMPLATE,
          template: Object.assign({}, templateNew),
          templatesOfGroup: this.templatesOfGroup,
          templateGroup: this.templateGroupSelected
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          this.templateLedService.addNewTemplate(result).subscribe(
            data => {
              this.templatesOfGroup.push(data);
              this.selectTemplate(this.templatesOfGroup[this.templatesOfGroup.length - 1]);
              this.saveDataSuccess.emit(true);
            },
            () => {
              this.showDialogError();
              return;
            }
          );
        }
      }
    );
  }

  /**
   * edit template
   */
  private editTemplate(): void {
    if (!this.templateGroupSelected || this.isShowedTemplateEditor) {
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogTemplateLedComponent,
      {
        data: {
          title: Constant.TITLE_EDIT_TEMPLATE,
          template: Object.assign({}, this.templateSelected),
          templatesOfGroup: this.templatesOfGroup.filter(template => template.id != this.templateSelected.id)
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          this.templateLedService.editTemplate(result).subscribe(
            data => {
              // changeTemplate
              let selectedIndex = this.templatesOfGroup.findIndex(template => template.id == data.id);
              this.templatesOfGroup[selectedIndex].name = data.name;
              this.selectTemplate(this.templatesOfGroup[selectedIndex]);
            },
            () => {
              this.showDialogError();
              return;
            }
          );
          this.saveDataSuccess.emit(true);
        }
      }
    );
  }

  /**
   * duplicate template
   * @returns
   */
  private duplicateTemplate(): void {
    if (this.isShowedTemplateEditor || !(this.templateGroupSelected && this.templateSelected)) {
      return;
    }
    if (!this.templateSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('led-layout-editor.title-error'),
          text: this.translateService.instant('led-layout-editor.select-template')
        }
      });
      return;
    }
    let templateName = this.getNameTemplate(this.templateSelected.name, this.templatesOfGroup, 0);
    if (templateName.length > Constant.MAX_LENGTH_TEMPLATE_NAME_LED) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('led-layout-editor.title-error'),
          text: Helper.formatString(this.translateService.instant('led-layout-editor.max-length-name'), `${this.MAX_LENGTH_NAME}`)
        },
        autoFocus: true
      });
      return;
    }
    this.templateLedService.duplicateTemplate(templateName, this.templateSelected.id).subscribe(
      data => {
        if (data) {
          this.templatesOfGroup.push(data);
          this.selectTemplate(this.templatesOfGroup[this.templatesOfGroup.length - 1]);
        }
      },
      () => {
        this.showDialogError();
        return;
      }
    );
  }

  /**
   * exit layout template detail -> layout template group
   */
  private exitLayoutTemplate(): void {
    // no change data -> exit
    if (!this.isChangedData) {
      this.isShowedTemplateEditor = false;
      this.dataService.sendData([Constant.IS_SHOW_CANVAS_LED, this.isShowedTemplateEditor]);
      return;
    }
    // change data -> confirm save
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('led-layout-editor.confirm-save'),
          button1: this.translateService.instant('led-layout-editor.btn-yes'),
          button2: this.translateService.instant('led-layout-editor.btn-no'),
          title: 'Confirmation'
        },
        autoFocus: false
      },
      result => {
        // click button No
        if (!result) {
          this.resetAfterExitLayoutTemplate();
          return;
        }
        // click button Yes
        this.saveAreas();
        const sub = this.saveDataSuccess.subscribe(isSuccess => {
          sub.unsubscribe();
          if (!isSuccess) {
            return;
          }
          this.resetAfterExitLayoutTemplate();
        });
      }
    );
  }

  /**
   * reset after exit layout template
   */
  private resetAfterExitLayoutTemplate(): void {
    this.isChangedData = false;
    this.isShowedTemplateEditor = false;
    this.dataService.sendData([Constant.IS_SHOW_CANVAS_LED, this.isShowedTemplateEditor]);
  }

  /**
   * get template name
   * @param templateDuplicateName
   * @param templates
   * @param count
   */
  private getNameTemplate(templateDuplicateName: string, templates: Array<TemplateLED>, count: number): string {
    let index = templates.findIndex(template => template.name == `${templateDuplicateName}_${count + 1}`);
    return index != -1 ? this.getNameTemplate(templateDuplicateName, templates, ++count) : `${templateDuplicateName}_${++count}`;
  }

  /**
   * filter template group by mode
   */
  public filterTemplateGroupByMode(): void {
    if (this.templateGroupModeFilter == LEDTemplateModeEnum.ALL) {
      this.templateGroups = this.templateGroupsOrigin;
    } else {
      this.templateGroups = this.templateGroupsOrigin.filter(templateGroup => templateGroup.templateMode == this.templateGroupModeFilter);
    }
    this.templateGroupSelected = undefined;
    this.templateSelected = undefined;
    this.dataService.sendData([Constant.IS_SELECTED_TEMPLATE_GROUP, this.templateGroupSelected]);
  }

  /**
   * select template group
   * @param templateGroup
   */
  public selectTemplateGroup(templateGroup: TemplateGroupLED): void {
    this.templateGroupSelected = templateGroup;
    this.dataService.sendData([Constant.IS_SELECTED_TEMPLATE_GROUP, this.templateGroupSelected]);
    this.templateSelected = undefined;
    this.getAllTemplateByTemplateGroupId(this.templateGroupSelected.id);
  }

  /**
   * get all template by template group id
   * @param templateGroupId
   */
  private getAllTemplateByTemplateGroupId(templateGroupId: Number): void {
    this.templateLedService.getAllTemplateByGroupId(templateGroupId).subscribe(
      data => {
        this.templatesOfGroup = data;
      },
      error => {
        this.showDialogError();
        return;
      }
    );
  }

  /**
   * select template
   * @param template
   */
  public selectTemplate(template: TemplateLED): void {
    this.templateSelected = template;
  }

  /**
   * delete template group
   */
  private deleteTemplateGroup(): void {
    if (this.isShowedTemplateEditor || !this.templateGroupSelected) {
      return;
    }
    if (!this.templateGroupSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('led-layout-editor.title-error'),
          text: this.translateService.instant('led-layout-editor.select-group')
        },
        autoFocus: true
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('led-layout-editor.confirm-delete'), this.templateGroupSelected.name),
          button1: this.translateService.instant('led-layout-editor.btn-yes'),
          button2: this.translateService.instant('led-layout-editor.btn-no'),
          title: 'Confirmation'
        },
        autoFocus: false
      },
      result => {
        if (result) {
          this.templateGroupLedService.deleteTemplateGroup(this.templateGroupSelected.id).subscribe(
            () => {
              let selectedIndexOrigin = this.templateGroupsOrigin.findIndex(
                templateGroup => templateGroup.id == this.templateGroupSelected.id
              );
              this.templateGroupsOrigin.splice(selectedIndexOrigin, 1);
              this.filterTemplateGroupByMode();
            },
            () => {
              this.showDialogError();
              return;
            }
          );
        }
      }
    );
  }

  /**
   * delete template
   */
  private deleteTemplate(): void {
    if (this.isShowedTemplateEditor || !(this.templateGroupSelected && this.templateSelected)) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('led-layout-editor.confirm-delete'), this.templateSelected.name),
          button1: this.translateService.instant('led-layout-editor.btn-yes'),
          button2: this.translateService.instant('led-layout-editor.btn-no'),
          title: 'Confirmation'
        },
        autoFocus: false
      },
      result => {
        if (result) {
          this.templateLedService.deleteTemplate(this.templateSelected.id).subscribe(
            () => {
              let selectedIndex = this.templatesOfGroup.findIndex(template => template.id == this.templateSelected.id);
              this.templatesOfGroup.splice(selectedIndex, 1);
              this.selectTemplate(this.templatesOfGroup[0]);
            },
            () => {
              this.showDialogError();
              return;
            }
          );
        }
      }
    );
  }

  /**
   * show template detail
   * @param template
   */
  public showTemplateDetail(template: TemplateLED): void {
    this.isShowedTemplateEditor = true;
    this.dataService.sendData([Constant.IS_SHOW_CANVAS_LED, this.isShowedTemplateEditor]);
    this.changeTemplateDetail(template);
    // get index word groups
    this.getAllIndexWordGroups();
  }

  /**
   * change template editor
   * @param template
   */
  public changeTemplateDetail(template: TemplateLED): void {
    // change data -> confirm save
    if (this.isChangedData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('led-layout-editor.confirm-save'),
            button1: this.translateService.instant('led-layout-editor.btn-yes'),
            button2: this.translateService.instant('led-layout-editor.btn-no'),
            title: 'Confirmation'
          },
          autoFocus: false
        },
        result => {
          if (result) {
            this.saveAreas();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (!isSuccess) {
                return;
              } else {
                this.isChangedData = false;
                this.changeTemplateDetail(template);
              }
            });
          } else {
            this.isChangedData = false;
            this.changeTemplateDetail(template);
          }
        }
      );
      return;
    }
    // handle when select template (layout template detail)
    this.areaSelected = undefined;
    this.toolEditTemplateSelected = EditTemplateToolsEnum.SELECT_AREA;
    this.templateSelected = template;
    this.templateSelected.width = this.templateGroupSelected.width * Constant.SCALE;
    this.templateSelected.height = this.templateGroupSelected.height * Constant.SCALE;
    this.templateSelected.templateGroupId = this.templateGroupSelected.id;
    this.fontsBitmap = [];
    this.fontService.getFontsBitmap().subscribe(data => {
      this.fontNamesBmp = data;
      this.fontsDisplay = data;
      this.areaLEDService.getAreasByTemplateId(this.templateSelected.id).subscribe(data => {
        this.handleDataAreas(data, true);
      });
    });
  }

  /**
   * handle data areas
   * @param data
   * @param isResetPanZoom
   */
  private handleDataAreas(data: any, isResetPanZoom?: boolean): void {
    let areasConvert = Helper.convertDataAreasLED(data);
    this.areas = areasConvert.sort((area1, area2) => {
      return area2.index - area1.index;
    });
    this.templateSelected.areas = this.areas;
    this.oldTemplateSelected = _.cloneDeep(this.templateSelected);
    // if no area => draw canvas empty
    if (!this.areas.length) {
      this.drawPreview(isResetPanZoom);
      return;
    }
    // get information font bitmap of areas to preview
    let infoBitmaps = this.areas
      .filter(item => item.checkTypeTextArea() && item.getArea().fontType == FontTypeEnum.BITMAP_FONT)
      ?.map(area => {
        return new FontCharacterBitmap(area.getArea().fontName, area.getArea().fontSize);
      });
    this.fontService.getFontsBitmapForAreas(infoBitmaps).subscribe(data => {
      this.fontsBitmap = data as [];
      this.drawPreview(isResetPanZoom);
    });
  }

  /**
   * draw preview
   */
  private drawPreview(isResetPanZoom?: boolean): void {
    this.changeDetectorRef.detectChanges();
    const myNode = document.getElementById(Constant.CANVAS_LED_CONTAINER_ID);
    while (myNode !== null && myNode.firstChild) {
      myNode.removeChild(myNode.firstChild);
    }
    if (isResetPanZoom) {
      this.scalePreview = new ScaleEntity('100', 1);
    }
    if (this.panzoom != undefined) {
      this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
    }
    this.panzoom = Panzoom(this.canvasLEDContainer?.nativeElement, {
      startScale: this.scalePreview.value,
      minScale: this.MINIMUM_SCALE_PREVIEW,
      maxScale: this.MAXIMUM_SCALE_PREVIEW
    });
    this.panzoom.setOptions({ disableZoom: true, disablePan: true, force: true });
    this.renderer.setStyle(this.canvasLEDContainer.nativeElement, 'cursor', 'default');
    this.drawLEDService.createCanvasTemplateLED(this.templateSelected, this.canvasLEDContainer, this.renderer);
    this.drawLEDService.createGridCanvasLayoutRealTime(this.templateSelected, this.renderer, this.canvasLEDContainer);
    this.drawLEDService.createCanvasLayoutRealTime(this.templateSelected, this.renderer, this.canvasLEDContainer);
    this.drawLEDService.setupTemplate(this.templateGroupSelected.displayModel);
    if (!this.areas.length) {
      return;
    }
    this.createAllCanvasAreaTemplate();
    this.drawLEDService.drawTemplateLEDLayoutEditor(this.areas, this.fontsBitmap);
  }

  /**
   * set reference point
   * @param refPoint
   */
  public setReferencePoint(refPoint: number): void {
    this.areaSelected.referencePoint = refPoint;
    this.oldValueX = this.areaSelected.posX;
    this.oldValueY = this.areaSelected.posY;
  }

  /**
   * change mode scroll
   */
  public changeModeScroll(): void {
    if (this.areaSelected.scrollStatus != ScrollStatusEnum.OFF) {
      this.areaSelected.scrollSpeed = SpeedEnum.LOW;
      this.areaSelected.scrollDirection = ScrollDirectionsEnum.LEFT;
    }
    if (!this.areaSelected.checkTypeTextArea()) {
      return;
    }
    // set scroll of adjacent area
    let index = this.areas.findIndex(area => area.name == this.areaSelected.getArea().linkWith);
    if (index != -1) {
      this.setScrollOfAdjacentArea(this.areas[index]);
      this.hideChangeOver(this.areas[index]);
    }
  }

  /**
   * hide changeover setting
   * @param area
   */
  private hideChangeOver(area: AreaLED): void {
    if (!this.areaSelected.getArea().areaLink) {
      return;
    }
    // check show/hide change over
    const isHiddenChangeOver =
      this.areaSelected.scrollStatus != ScrollStatusEnum.OFF &&
      this.areaSelected.scrollDirection != ScrollDirectionsEnum.LEFT &&
      this.areaSelected.scrollDirection != ScrollDirectionsEnum.RIGHT;

    // hide change over if scroll direction is up/down/semi-up/semi-down
    this.areaSelected.getArea().isHideChangeOver = isHiddenChangeOver;
    area.getArea().isHideChangeOver = isHiddenChangeOver;
  }

  /**
   * set scroll adjacent area
   * @param area
   */
  private setScrollOfAdjacentArea(area: AreaLED): void {
    area.scrollStatus = this.areaSelected.scrollStatus;
    area.scrollDirection = this.areaSelected.scrollDirection;
    area.scrollSpeed = this.areaSelected.scrollSpeed;
    area.pauseTime = this.areaSelected.pauseTime;
  }

  /**
   * change scroll speed
   */
  public changeScrollSpeed(): void {
    // set scroll of adjacent area
    let index = this.areas.findIndex(area => area.name == this.areaSelected.getArea().linkWith);
    if (index != -1) {
      this.setScrollOfAdjacentArea(this.areas[index]);
    }
  }

  /**
   * change pause time
   */
  public changePauseTime(): void {
    // set scroll of adjacent area
    let index = this.areas.findIndex(area => area.name == this.areaSelected.getArea().linkWith);
    if (index != -1) {
      this.setScrollOfAdjacentArea(this.areas[index]);
    }
  }

  /**
   * get index word group name
   * @param area Area
   */
  private getIndexWordGroupName(area: AreaLED): void {
    let indexWordGroup = this.indexWordGroups.find(indexWordData => indexWordData.id == area?.getArea().indexWordGroupId);
    if (indexWordGroup) {
      area['indexWordGroupName'] = indexWordGroup.name;
    }
  }

  /**
   * change setting reference source
   */
  public changeSettingReferenceSource(): void {
    const linkReferenceData = this.areaSelected.getArea().linkReferenceData;
    const isTextArea = this.areaSelected.checkTypeTextArea();
    if (isTextArea) {
      switch (linkReferenceData) {
        case LinkDataTextLEDEnum.BUS_STOP_NAME:
        case LinkDataTextLEDEnum.ADJUSTED_BUS_STOP_NAME:
          break;
        case LinkDataTextLEDEnum.INDEX_WORD:
          // set indexWordGroupId, referencePositionColumn default when choose index word
          this.getIndexWordGroupName(this.areaSelected);
          this.areaSelected.getArea().indexWordGroupId = this.indexWordGroups[0].id;
          if (this.areaSelected.getArea().areaLink && this.areaSelected.getArea().linkWith) {
            this.areaSelected.getArea().referencePositionColumn = ReferencePositionColumnEDSEnum.BUS_STOP_NAME;
          } else {
            this.areaSelected.getArea().referencePositionColumn = ReferencePositionColumnEDSEnum.ROUTE_NO;
          }
          break;
        default:
          // reset area link of adjacent area
          let index1 = this.areas.findIndex(area => area.name == this.areaSelected.getArea().linkWith);
          if (index1 != -1) {
            this.resetAreaLink(this.areas[index1]);
          }
          // reset area link of area selected
          this.resetAreaLink(this.areaSelected);
          break;
      }
    } else {
      // set indexWordGroupId, referencePositionColumn default when choose index word
      this.getIndexWordGroupName(this.areaSelected);
      if (linkReferenceData == LinkDataPictureLEDEnum.INDEX_WORD) {
        if (this.indexWordGroups.length) {
          this.areaSelected.getArea().indexWordGroupId = this.indexWordGroups[0].id;
          this.areaSelected.getArea().referencePositionColumn = ReferencePositionColumnEDSEnum.ROUTE_NO;
        }
      }
    }
  }

  /**
   * change reference position column
   */
  public changeReferencePositionColumn(): void {
    if (
      this.areaSelected.getArea().referencePositionColumn != ReferencePositionColumnEDSEnum.BUS_STOP_NAME &&
      this.areaSelected.getArea().referencePositionColumn != ReferencePositionColumnEDSEnum.ADJUSTED_BUS_STOP_NAME
    ) {
      // set scroll of adjacent area
      let index = this.areas.findIndex(area => area.name == this.areaSelected.getArea().linkWith);
      if (index != -1) {
        this.resetAreaLink(this.areas[index]);
      }
      // reset area link of area selected
      this.resetAreaLink(this.areaSelected);
    }
  }

  /**
   * reset area link
   * @param area
   */
  private resetAreaLink(area: AreaLED): void {
    area.getArea().areaLink = false;
    area.getArea().linkWith = null;
    area.getArea().isHideChangeOver = false;
  }

  /**
   * change setting area is TextArea
   * @param area Area
   */
  public changeSettingTextArea(area: AreaLED): void {
    let areaText = area as TextAreaLED;
    if (areaText.text == null || areaText.fontSize == null) {
      return;
    }
    if (areaText.text.length > this.MAXIMUM_TEXT) {
      return;
    }
    this.isEditText = true;
  }

  /**
   * change font
   * @param fontName
   */
  public changeFont(fontName: string): void {
    let oldFontType = _.cloneDeep(this.areaSelected.getArea().fontType);
    let index = this.fontsDisplay.findIndex(font => font.name == fontName);
    if (index == -1) {
      return;
    }
    // font of area selected is font PC
    if (this.fontsDisplay[index].type == FontTypeEnum.PC_FONT) {
      this.areaSelected.getArea().fontType = FontTypeEnum.PC_FONT;
      this.areaSelected.getArea().fontSize = Constant.FONT_SIZE_PC_DEFAULT;
      // if old font type of area selected is font bitmap -> clear area
      if (oldFontType == FontTypeEnum.BITMAP_FONT) {
        this.drawLEDService.clearCanvas(this.areaSelected?.canvas);
        delete this.areaSelected?.canvas;
        this.drawLEDService.createCanvasArea(this.areaSelected, Constant.SCALE, this.renderer, this.canvasLEDContainer);
        this.setBorderAreaSelected(this.areaSelected);
      }
      // draw area
      this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
    } else {
      this.areaSelected.getArea().fontType = FontTypeEnum.BITMAP_FONT;
      // get font size bitmap of font selected
      this.fontService.getFontSizeBitmaps(fontName).subscribe(data => {
        this.areaSelected.getArea().fontSizesBitmap = data;
        if (this.areaSelected.getArea().fontSizesBitmap?.length) {
          this.areaSelected.getArea().fontSize = this.areaSelected.getArea().fontSizesBitmap[0];
          this.getFontBitmapsArea(this.areaSelected);
        }
      });
    }
  }

  /**
   * change font size
   */
  public changeFontSize(): void {
    let index = this.fontsBitmap.findIndex(
      font => font['name'] == this.areaSelected.getArea().fontName && font['size'] == this.areaSelected.getArea().fontSize
    );
    if (index != -1) {
      this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
      return;
    }
    this.fontService
      .getFontsBitmapForAreas([new FontCharacterBitmap(this.areaSelected.getArea().fontName, this.areaSelected.getArea().fontSize)])
      .subscribe(data => {
        this.fontsBitmap = this.fontsBitmap.concat(data as []) as [];
        this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
      });
  }

  /**
   * focus out to edit area name
   * @param areaEdit
   */
  public focusoutToEditName(areaEdit: AreaLED): void {
    if (!areaEdit) {
      return;
    }
    const areaName = areaEdit.name.trim();
    // show error if area name empty
    if (areaName == '') {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('led-layout-editor.title-error'),
            text: this.translateService.instant('led-layout-editor.area-empty')
          },
          autoFocus: true
        },
        () => {
          document.getElementById(this.EDIT_AREA_NAME_ID).focus();
        }
      );
      return;
    }
    if (areaName.length > this.MAX_LENGTH_AREA_NAME) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('led-layout-editor.title-error'),
            text: Helper.formatString(
              this.translateService.instant('led-layout-editor.max-length-area-name'),
              `${this.MAX_LENGTH_AREA_NAME}`
            )
          },
          autoFocus: true
        },
        () => {
          document.getElementById(this.EDIT_AREA_NAME_ID).focus();
        }
      );
      return;
    }
    // show error if area name exists
    if (
      this.areas
        .filter(area => area.id != areaEdit.id)
        .map(area => area.name)
        .findIndex(name => name == areaName) != -1
    ) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('led-layout-editor.title-error'),
            text: this.translateService.instant('led-layout-editor.area-exists')
          },
          autoFocus: true
        },
        () => {
          document.getElementById(this.EDIT_AREA_NAME_ID).focus();
        }
      );
      return;
    }
    areaEdit.name = areaName;
    areaEdit.isEditName = false;
  }

  /**
   * change font color
   * @param event
   */
  public changeFontColor(event: any): void {
    if (!this.areaSelected.checkTypeTextArea()) {
      return;
    }
    (<TextAreaLED>this.areaSelected).fontColor = event;
    this.fontColor = event;
    this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
  }

  /**
   * change background color
   * @param event
   */
  public changeBackgroundColor(event: any): void {
    if (!this.areaSelected.checkTypeTextArea()) {
      return;
    }
    (<TextAreaLED>this.areaSelected).backgroundColor = event;
    this.backgroundColor = event;
    this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
  }

  /**
   * change outline color
   * @param event
   */
  public changeOutlineColor(event: any): void {
    if (!this.areaSelected.checkTypeTextArea()) {
      return;
    }
    (<TextAreaLED>this.areaSelected).outlineColor = event;
    this.outlineColor = event;
  }

  /**
   * hide color picker
   * @param event
   */
  public hideColorPicker(event: any): void {
    this.isShowColorPicker = event;
  }

  /**
   * save information areas
   */
  private saveAreas(): void {
    // validate reference source
    if (!this.validateReferenceSource()) {
      return;
    }
    // save data
    this.areaLEDService.updateDetailedAreas(Helper.convertDataAreasLEDBackward(this.areas), this.templateSelected.id).subscribe(
      data => {
        this.areaSelected = undefined;
        this.toolEditTemplateSelected = EditTemplateToolsEnum.SELECT_AREA;
        this.handleDataAreas(data);
        // display toast save success
        this.toast.success(this.translateService.instant('led-layout-editor.save-success'), '');
        this.saveDataSuccess.emit(true);
      },
      error => {
        this.showDialogError();
        this.saveDataSuccess.emit(false);
        return;
      }
    );
  }

  /**
   * set horizontal align text
   * @param align
   */
  public setHorizontalAlignment(align: AlignmentEnum): void {
    (<TextAreaLED>this.areaSelected.getArea()).horizontalTextAlignment = align;
    this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
  }

  /**
   * create all canvas area template
   */
  private createAllCanvasAreaTemplate(): void {
    this.areas.forEach(area => {
      this.drawLEDService.createCanvasArea(area, Constant.SCALE, this.renderer, this.canvasLEDContainer);
    });
  }

  /**
   * change value position
   * @param elementInput
   * @param idInput
   */
  public changeValuePosition(elementInput: ElementInputLED, idInput: any): void {
    let valueDecimal = Helper.handlingDecimal(idInput.value);
    idInput.value = valueDecimal;
    switch (elementInput) {
      case ElementInputLED.X_INPUT:
        this.areaSelected.displayPosX = valueDecimal;
        if (
          valueDecimal == null ||
          this.areaSelected.posX * Constant.SCALE > this.templateSelected.width - this.areaSelected.width * Constant.SCALE ||
          this.areaSelected.posX * Constant.SCALE < 0
        ) {
          this.isInvalid = true;
          return;
        }
        break;
      case ElementInputLED.Y_INPUT:
        this.areaSelected.displayPosY = valueDecimal;
        if (
          valueDecimal == null ||
          this.areaSelected.posY * Constant.SCALE > this.templateSelected.height - this.areaSelected.height * Constant.SCALE ||
          this.areaSelected.posY * Constant.SCALE < 0
        ) {
          this.isInvalid = true;
          return;
        }
        break;
      case ElementInputLED.WIDTH_INPUT:
        this.areaSelected.width = valueDecimal;
        if (!valueDecimal || valueDecimal * Constant.SCALE > this.templateSelected.width - this.areaSelected.posX * Constant.SCALE) {
          this.isInvalid = true;
          return;
        }
        break;
      case ElementInputLED.HEIGHT_INPUT:
        this.areaSelected.height = valueDecimal;
        if (!valueDecimal || valueDecimal * Constant.SCALE > this.templateSelected.height - this.areaSelected.posY * Constant.SCALE) {
          this.isInvalid = true;
          return;
        }
        break;
      default:
        break;
    }
    this.setAreaLinkWith();
    this.rePreviewAreaWhenChangePosition(this.areaSelected);
    this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
    this.isInvalid = false;
  }

  /**
   * set area link with
   */
  private setAreaLinkWith(): void {
    if (!this.areaSelected.checkTypeTextArea() || this.areaSelected.isFix) {
      return;
    }
    this.getAreasLinkWith();
  }

  /**
   * get all index word groups
   */
  private getAllIndexWordGroups(): void {
    this.indexWordGroupService.getIndexWordGroups().subscribe(
      (data: Array<IndexWordGroup>) => {
        this.indexWordGroups = data;
      },
      () => {
        this.showDialogError();
        return;
      }
    );
  }

  /**
   * hide show list template
   */
  public hideShowTemplates(): void {
    this.isHiddenTemplates = !this.isHiddenTemplates;
  }

  /**
   * hide align tool
   */
  public hideAlignTool(): void {
    if (!this.isShowAlignTool) {
      return;
    }
    this.isShowAlignTool = false;
  }

  /**
   * get selected areas
   */
  private getSelectedAreas(): Array<AreaLED> {
    return this.areas.filter(area => area.isSelected);
  }

  /**
   * align left
   * @param event
   */
  public alignLeft(event: any): void {
    event.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    let minX = Math.min(...areas.map(area => area.posX));
    areas.forEach(area => {
      area.posX = minX;
      this.rePreviewAreaWhenChangePosition(area);
      this.drawLEDService.drawAreaLEDLayoutEditor(area, this.fontsBitmap);
    });
  }

  /**
   * align center
   * @param event
   */
  public alignCenter(event: any): void {
    event.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    let minX = Math.min(...areas.map(area => area.posX));
    let maxX = Math.max(...areas.map(area => area.posX + area.width));
    let centerX = (minX + maxX) / 2;
    areas.forEach(area => {
      area.posX = Math.ceil(centerX - area.width / 2);
      this.rePreviewAreaWhenChangePosition(area);
      this.drawLEDService.drawAreaLEDLayoutEditor(area, this.fontsBitmap);
    });
  }

  /**
   * align right
   * @param event
   */
  public alignRight(event: any): void {
    event.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    let maxX = Math.max(...areas.map(area => area.posX + area.width));
    areas.forEach(area => {
      area.posX = maxX - area.width;
      this.rePreviewAreaWhenChangePosition(area);
      this.drawLEDService.drawAreaLEDLayoutEditor(area, this.fontsBitmap);
    });
  }

  /**
   * align top
   * @param event
   */
  public alignTop(event: any): void {
    event.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    // align for selected area
    let minY = Math.min(...areas.map(area => area.posY));
    areas.forEach(area => {
      area.posY = minY;
      this.rePreviewAreaWhenChangePosition(area);
      this.drawLEDService.drawAreaLEDLayoutEditor(area, this.fontsBitmap);
    });
  }

  /**
   * align middle
   * @param event
   */
  public alignMiddle(event: any): void {
    event.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    let minY = Math.min(...areas.map(area => area.posY));
    let maxY = Math.max(...areas.map(area => area.posY + area.height));
    let centerY = (minY + maxY) / 2;
    areas.forEach(area => {
      area.posY = Math.ceil(centerY - area.height / 2);
      this.rePreviewAreaWhenChangePosition(area);
      this.drawLEDService.drawAreaLEDLayoutEditor(area, this.fontsBitmap);
    });
  }

  /**
   * align bottom
   * @param event
   */
  public alignBottom(event: any): void {
    event.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    let maxY = Math.max(...areas.map(area => area.posY + area.height));
    areas.forEach(area => {
      area.posY = maxY - area.height;
      this.rePreviewAreaWhenChangePosition(area);
      this.drawLEDService.drawAreaLEDLayoutEditor(area, this.fontsBitmap);
    });
  }

  /**
   * distribute horizontal
   * @param event
   */
  public distributeHorizontal(event: any): void {
    event.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length < 3) {
      return;
    }
    areas = areas.sort((area1, area2) => {
      let cenX1 = area1.posX + area1.width / 2;
      let cenX2 = area2.posX + area2.width / 2;
      if (cenX1 > cenX2) {
        return 1;
      } else if (cenX1 < cenX2) {
        return -1;
      } else {
        return 0;
      }
    });

    let widthSum = areas
      .slice(1, areas.length - 1)
      .map(area => area.width)
      .reduce((preValue, currentValue) => preValue + currentValue);
    let minX = areas[0].posX + areas[0].width;
    let maxX = areas[areas.length - 1].posX;
    let d = (maxX - minX - widthSum) / (areas.length - 1);
    for (let i = 0; i < areas.length - 2; i++) {
      areas[i + 1].posX = Math.ceil(areas[i].posX + areas[i].width + d);
      if (areas[i + 1].posX < 0) {
        areas[i + 1].posX = 0;
      }
      if (areas[i + 1].posX > this.templateSelected.width / Constant.SCALE - areas[i + 1].width) {
        areas[i + 1].posX = this.templateSelected.width / Constant.SCALE - areas[i + 1].width;
      }
      this.rePreviewAreaWhenChangePosition(areas[i + 1]);
      this.drawLEDService.drawAreaLEDLayoutEditor(areas[i + 1], this.fontsBitmap);
    }
  }

  /**
   * distribute vertical
   * @param event
   */
  public distributeVertical(event: any): void {
    event.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length < 3) {
      return;
    }
    areas = areas.sort((area1, area2) => {
      let cenY1 = area1.posY + area1.height / 2;
      let cenY2 = area2.posY + area2.height / 2;
      if (cenY1 > cenY2) {
        return 1;
      } else if (cenY1 < cenY2) {
        return -1;
      } else {
        return 0;
      }
    });
    let heightSum = areas
      .slice(1, areas.length - 1)
      .map(area => area.height)
      .reduce((preValue, currentValue) => preValue + currentValue);
    let minY = areas[0].posY + areas[0].height;
    let maxY = areas[areas.length - 1].posY;
    let d = (maxY - minY - heightSum) / (areas.length - 1);
    for (let i = 0; i < areas.length - 2; i++) {
      areas[i + 1].posY = Math.ceil(areas[i].posY + areas[i].height + d);
      if (areas[i + 1].posY < 0) {
        areas[i + 1].posY = 0;
      }
      if (areas[i + 1].posY > this.templateSelected.height / Constant.SCALE - areas[i + 1].height) {
        areas[i + 1].posY = this.templateSelected.height / Constant.SCALE - areas[i + 1].height;
      }
      this.rePreviewAreaWhenChangePosition(areas[i + 1]);
      this.drawLEDService.drawAreaLEDLayoutEditor(areas[i + 1], this.fontsBitmap);
    }
  }

  /**
   * change scale preview
   * @param idInput
   */
  public changeScalePreview(idInput: any): void {
    let valueDecimal = Helper.handlingDecimal(idInput.value);
    idInput.value = valueDecimal;
    if (idInput.value == '' || idInput.value / 100 < this.MINIMUM_SCALE_PREVIEW || idInput.value / 100 > this.MAXIMUM_SCALE_PREVIEW) {
      return;
    }
    this.scalePreview.name = idInput.value;
    this.scalePreview.value = idInput.value / 100;
    // Reset panzoom
    if (this.panzoom) {
      this.panzoom.reset({
        startScale: this.scalePreview.value,
        minScale: this.MINIMUM_SCALE_PREVIEW,
        maxScale: this.MAXIMUM_SCALE_PREVIEW,
        startX: this.panzoom.getPan().x,
        startY: this.panzoom.getPan().y
      });
    }
  }

  /**
   * validate setting area
   * @param elementInput
   * @param value
   */
  public focusoutToValidate(elementInput: ElementInputLED): void {
    let element: any = document.getElementById(elementInput);
    if (!element) {
      return;
    }
    let errMess = undefined;
    let value = element.value.trim();
    switch (elementInput) {
      case ElementInputLED.X_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('led-layout-editor.x-empty');
          this.areaSelected.posX = this.oldValueX;
          break;
        }
        if (this.areaSelected.posX > this.templateSelected.width / Constant.SCALE - this.areaSelected.width || this.areaSelected.posX < 0) {
          errMess = this.translateService.instant('led-layout-editor.x-invalid');
          this.areaSelected.posX = this.oldValueX;
          break;
        }
        this.oldValueX = this.areaSelected.posX;
        break;
      case ElementInputLED.Y_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('led-layout-editor.y-empty');
          this.areaSelected.posY = this.oldValueY;
          break;
        }
        if (
          this.areaSelected.posY > this.templateSelected.height / Constant.SCALE - this.areaSelected.height ||
          this.areaSelected.posY < 0
        ) {
          errMess = this.translateService.instant('led-layout-editor.y-invalid');
          this.areaSelected.posY = this.oldValueY;
          break;
        }
        this.oldValueY = this.areaSelected.posY;
        break;
      case ElementInputLED.WIDTH_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('led-layout-editor.w-empty');
          this.areaSelected.width = this.oldValueW;
          break;
        }
        if (value < this.MINIMUM_W) {
          errMess = Helper.formatString(this.translateService.instant('led-layout-editor.w-min'), `${this.MINIMUM_W}`);
          this.areaSelected.width = this.oldValueW;
          break;
        }
        if (value > this.templateSelected.width / Constant.SCALE - this.areaSelected.posX) {
          errMess = this.translateService.instant('led-layout-editor.w-invalid');
          this.areaSelected.width = this.oldValueW;
          break;
        }
        this.oldValueW = +value;
        break;
      case ElementInputLED.HEIGHT_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('led-layout-editor.h-empty');
          this.areaSelected.height = this.oldValueH;
          break;
        }
        if (value < this.MINIMUM_H) {
          errMess = Helper.formatString(this.translateService.instant('led-layout-editor.h-min'), `${this.MINIMUM_H}`);
          this.areaSelected.height = this.oldValueH;
          break;
        }
        if (value > this.templateSelected.height / Constant.SCALE - this.areaSelected.posY) {
          errMess = this.translateService.instant('led-layout-editor.h-invalid');
          this.areaSelected.height = this.oldValueH;
          break;
        }
        this.oldValueH = +value;
        break;
      case ElementInputLED.FONT_SIZE_INPUT:
        if (value == '' || value < this.MINIMUM_FONT_SIZE || value > this.MAXIMUM_FONT_SIZE) {
          errMess = this.translateService.instant('led-layout-editor.font-size-invalid');
          break;
        }
        break;
      case ElementInputLED.TEXT_INPUT:
        if (value.length > this.MAXIMUM_TEXT) {
          errMess = Helper.formatString(this.translateService.instant('led-layout-editor.text-max-length'), `${this.MAXIMUM_TEXT}`);
          break;
        }
        break;
      case ElementInputLED.PAUSE_TIME_INPUT:
        if (value == '' || value < this.MINIMUM_PAUSE_TIME || value > this.MAXIMUM_PAUSE_TIME) {
          errMess = this.translateService.instant('led-layout-editor.pause-time-invalid');
          break;
        }
        break;
      case ElementInputLED.LEAD_SPACING_INPUT:
        if (value == '' || value < this.MINIMUM_LEAD_SPACING || value > this.MAXIMUM_LEAD_SPACING) {
          errMess = this.translateService.instant('led-layout-editor.lead-spacing-invalid');
          break;
        }
        break;
      case ElementInputLED.LETTER_SPACING_INPUT:
        if (value == '' || value < this.MINIMUM_LETTER_SPACING || value > this.MAXIMUM_LETTER_SPACING) {
          errMess = this.translateService.instant('led-layout-editor.letter-spacing-invalid');
          break;
        }
        break;
      case ElementInputLED.BASELINE_HEIGHT_INPUT:
        if (value == '' || value < this.MINIMUM_BASELINE_HEIGHT || value > this.MAXIMUM_BASELINE_HEIGHT) {
          errMess = this.translateService.instant('led-layout-editor.baseline-height-invalid');
          break;
        }
        break;
      case ElementInputLED.DURATION_INPUT:
        if (value == '' || value < this.MINIMUM_DURATION || value > this.MAXIMUM_DURATION) {
          errMess = this.translateService.instant('led-layout-editor.duration-invalid');
          break;
        }
        break;
      case ElementInputLED.OTHER_DELIMITERS_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('led-layout-editor.delimiter-empty');
          break;
        }
        if (value.length > this.MAXIMUM_OTHER_DELIMITERS) {
          errMess = Helper.formatString(
            this.translateService.instant('led-layout-editor.delimiter-max-length'),
            `${this.MAXIMUM_OTHER_DELIMITERS}`
          );
          break;
        }
        break;
      case ElementInputLED.SCALE_PREVIEW_INPUT:
        if (value == '' || +value / 100 < this.MINIMUM_SCALE_PREVIEW || +value / 100 > this.MAXIMUM_SCALE_PREVIEW) {
          errMess = this.translateService.instant('led-layout-editor.scale-invalid');
          break;
        }
        break;
      default:
        break;
    }
    if (errMess) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('led-layout-editor.title-error'),
            text: errMess
          },
          autoFocus: true
        },
        () => {
          element.select();
          element.focus();
          if (
            elementInput == ElementInputLED.X_INPUT ||
            elementInput == ElementInputLED.Y_INPUT ||
            elementInput == ElementInputLED.WIDTH_INPUT ||
            elementInput == ElementInputLED.HEIGHT_INPUT
          ) {
            this.rePreviewAreaWhenChangePosition(this.areaSelected);
            this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
          }
        }
      );
    } else {
      this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
    }
    this.isInvalid = false;
  }

  /**
   * clear all border canvas
   */
  public clearAllBorderCanvas(): void {
    this.isClearAllBorderCanvas = !this.isClearAllBorderCanvas;
    // clear all border area canvas
    if (this.isClearAllBorderCanvas) {
      this.areas.forEach(area => {
        this.renderer.removeStyle(area.canvas, 'border');
      });
    } else {
      // set border area
      this.areas.forEach(area => {
        // set border area selected
        if (this.areaSelected?.symbol == area.symbol) {
          area.isSelected = true;
          this.setBorderAreaSelected(area);
          // set border area no selected
        } else {
          area.isSelected = false;
          this.setBorderAreaNoSelected(area);
        }
      });
    }
  }

  /**
   * hide areas
   */
  public hideAreas(): void {
    if (!this.areaSelected) {
      return;
    }
    this.isShowAreasOnPreview = !this.isShowAreasOnPreview;
    if (!this.areasSelected?.length) {
      this.hideArea(this.areaSelected);
    }
    if (this.isShowAreasOnPreview) {
      this.areasSelected.forEach(area => {
        this.hideArea(area);
      });
    } else {
      this.areasSelected.forEach(area => {
        if (area.isHidden) {
          return;
        }
        this.hideArea(area);
      });
    }
  }

  /**
   * hide / show area on preview
   * @param area area is selected
   */
  public hideArea(area: AreaLED): void {
    if (!area) {
      return;
    }
    area.isHidden = !area.isHidden;
    if (area.isHidden) {
      this.renderer.setStyle(area.canvas, 'visibility', 'hidden');
    } else {
      this.renderer.setStyle(area.canvas, 'visibility', 'visible');
    }
  }

  /**
   * hide show list area
   */
  public hideShowAreas(): void {
    this.isHiddenAreas = !this.isHiddenAreas;
  }

  /**
   * hide show setting area
   */
  public hideShowSettingArea(): void {
    this.isHiddenSettingArea = !this.isHiddenSettingArea;
  }

  /**
   * select area
   * @param area
   * @param isNoResetAreasSelected
   */
  public selectArea(area: AreaLED, isNoResetAreasSelected?: boolean): void {
    let oldAreaSelected = _.cloneDeep(this.areaSelected);
    // reset border areas selected
    this.resetBorderCanvasAreas();
    this.areaSelected = area;
    this.areaSelected.isSelected = true;
    // set border area selected
    this.setBorderAreaSelected(area);
    if (!isNoResetAreasSelected) {
      this.areasSelected = [];
      this.areasSelected.push(area);
    }
    // area is TextArea
    if (this.areaSelected.checkTypeTextArea()) {
      if (this.templateGroupSelected.displayModel == DisplayModelEnum.FULL_COLOR) {
        this.fontColor = this.areaSelected.getArea().fontColor;
        this.backgroundColor = this.areaSelected.getArea().backgroundColor;
        this.outlineColor = this.areaSelected.getArea().outlineColor;
      }
      // get areas link with
      this.getAreasLinkWith();
      this.hideChangeOver(area);
      // get fonts bitmap for area
      if (!this.fontsBitmap.length && this.areaSelected.getArea().fontType == FontTypeEnum.BITMAP_FONT) {
        this.getFontBitmapsArea(this.areaSelected);
      }

      // get font size of area selected
      if (
        this.areaSelected.symbol != oldAreaSelected?.symbol &&
        this.areaSelected.getArea().fontName == oldAreaSelected?.getArea().fontName
      ) {
        this.areaSelected.getArea().fontSizesBitmap = oldAreaSelected.getArea().fontSizesBitmap;
      } else if (!this.areaSelected.getArea().fontSizesBitmap?.length) {
        this.fontService.getFontSizeBitmaps(area.getArea().fontName).subscribe(data => {
          this.isMouseDown = false;
          this.areaSelected.getArea().fontSizesBitmap = data;
        });
      }
    }
    this.oldValueX = this.areaSelected.posX;
    this.oldValueY = this.areaSelected.posY;
    this.oldValueW = this.areaSelected.width;
    this.oldValueH = this.areaSelected.height;
  }

  /**
   * get fonts area
   * @param area
   */
  private getFontBitmapsArea(area: AreaLED): void {
    this.fontService
      .getFontsBitmapForAreas([new FontCharacterBitmap(this.areaSelected.getArea().fontName, this.areaSelected.getArea().fontSize)])
      .subscribe(data => {
        this.isMouseDown = false;
        this.fontsBitmap = this.fontsBitmap.concat(data as []) as [];
        // draw area
        this.drawLEDService.drawAreaLEDLayoutEditor(area, this.fontsBitmap);
      });
  }

  /**
   * get areas link with
   */
  private getAreasLinkWith(): void {
    this.areaSelected.getArea().areasLinkWith = this.areas.filter(area => {
      if (!area.checkTypeTextArea() || area.symbol == this.areaSelected.symbol) {
        return false;
      }
      if (
        area.isFix == this.areaSelected.isFix &&
        area.width == this.areaSelected.width &&
        area.posX == this.areaSelected.posX &&
        (area.posY + area.height == this.areaSelected.posY || area.posY == this.areaSelected.posY + this.areaSelected.height)
      ) {
        return true;
      }
    });

    if (!this.areaSelected.getArea().areasLinkWith?.length) {
      // set area link of adjacent area is off
      let index = this.areas.findIndex(area => area.name == this.areaSelected.getArea().linkWith);
      if (index != -1) {
        this.handleOnOffAreaLink(this.areas[index], false, null);
      }
      // set area link of area selected is off
      this.handleOnOffAreaLink(this.areaSelected, false, null);
    }
  }

  /**
   * change link with
   */
  public changeLinkWith(): void {
    const areaNameLinkWith = this.areaSelected.getArea().linkWith;
    // set area link of adjacent area is off
    let index = this.areas.findIndex(
      area =>
        area.checkTypeTextArea() &&
        area.symbol != this.areaSelected.symbol &&
        (area.getArea().linkWith == this.areaSelected.getArea().name || area.getArea().linkWith == areaNameLinkWith)
    );
    if (index != -1) {
      this.handleOnOffAreaLink(this.areas[index], false, null);
    }

    // set area link of adjacent area is on
    let indexAreaLinkWith = this.areas.findIndex(
      item => item.checkTypeTextArea() && item.symbol != this.areaSelected.symbol && item.getArea().name == areaNameLinkWith
    );
    if (indexAreaLinkWith != -1) {
      this.handleOnOffAreaLink(this.areas[indexAreaLinkWith], true, this.areaSelected.getArea().name);
    }
  }

  /**
   * handle on off area link for area
   * @param area
   * @param isAreaLink
   * @param linkWith
   */
  private handleOnOffAreaLink(area: AreaLED, isAreaLink: boolean, linkWith: string) {
    area.getArea().areaLink = isAreaLink;
    area.getArea().linkWith = linkWith;
    if (isAreaLink) {
      area.scrollStatus = this.areaSelected.scrollStatus;
      area.scrollSpeed = this.areaSelected.scrollSpeed;
      area.scrollDirection = this.areaSelected.scrollDirection;
      area.pauseTime = this.areaSelected.pauseTime;
      if (!area.isFix) {
        this.hideChangeOver(area);
        area.getArea().linkReferenceData = LinkDataTextLEDEnum.BUS_STOP_NAME;
        this.areaSelected.getArea().linkReferenceData = LinkDataTextLEDEnum.BUS_STOP_NAME;
      }
    } else {
      area.getArea().isHideChangeOver = false;
      if (area.scrollDirection == ScrollDirectionsEnum.SEMI_UP || area.scrollDirection == ScrollDirectionsEnum.SEMI_DOWN) {
        area.scrollDirection = ScrollDirectionsEnum.LEFT;
      }
    }
  }

  /**
   * edit name area
   * @param area
   * @returns
   */
  public editNameArea(area: AreaLED): void {
    if (!area) {
      return;
    }
    // editing area => return
    if (this.areas.findIndex(areaSelect => areaSelect.isEditName) != -1) {
      return;
    }
    if (!area.isEditName) {
      area.isEditName = true;
    }
  }

  /**
   * delete area
   * @param area
   * @returns
   */
  public deleteArea(area: AreaLED): void {
    if (!area) {
      return;
    }
    // show popup confirm
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('led-layout-editor.confirm-delete'), area.name),
          button1: this.translateService.instant('led-layout-editor.btn-yes'),
          button2: this.translateService.instant('led-layout-editor.btn-no'),
          title: 'Confirmation'
        },
        autoFocus: false
      },
      result => {
        if (!result) {
          return;
        }
        this.areas.forEach((item, index) => {
          if (item === area) {
            this.renderer.removeChild(this.canvasLEDContainer.nativeElement, area.canvas);
            this.areas.splice(index, 1);
            if (this.areas?.length) {
              this.selectArea(this.areas[0]);
            } else {
              this.areaSelected = undefined;
            }
          }
        });
        this.setZIndexArea();
      }
    );
  }

  /**
   * show dialog error
   */
  private showDialogError(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('led-layout-editor.title-error'),
        text: this.translateService.instant('led-layout-editor.an-error')
      },
      autoFocus: true
    });
  }

  /**
   * toggle area link
   */
  public toggleAreaLink(): void {
    this.areaSelected.getArea().areaLink = !this.areaSelected.getArea().areaLink;
    if (!this.areaSelected.getArea().areaLink) {
      // set area link of adjacent area is off
      let index = this.areas.findIndex(area => area.name == this.areaSelected.getArea().linkWith);
      if (index != -1) {
        this.areas[index].getArea().areaLink = false;
        this.areas[index].getArea().isHideChangeOver = false;
      }
      this.areaSelected.getArea().isHideChangeOver = false;
      // reset scrollDirection if area link off
      if (
        this.areaSelected.getArea().scrollDirection == ScrollDirectionsEnum.SEMI_UP ||
        this.areaSelected.getArea().scrollDirection == ScrollDirectionsEnum.SEMI_DOWN
      ) {
        this.areaSelected.getArea().scrollDirection = ScrollDirectionsEnum.LEFT;
      }
    } else {
      this.areaSelected.getArea().linkWith = null;
    }
  }

  /**
   * toggle change over
   */
  public toggleChangeover(): void {
    this.areaSelected.getArea().changeover = !this.areaSelected.getArea().changeover;
    if (!this.areaSelected.getArea().changeover) {
      this.areaSelected.getArea().delimiters = DelimitersEnum.SEMICOLON;
    }
  }

  /**
   * get new area name
   * @param isAreaText
   * @param isFix
   */
  private getNewNameArea(isAreaText: boolean, isFix: boolean): string {
    let areaName = (isFix ? 'Fix ' : 'Link ') + (isAreaText ? 'text' : 'picture');
    let countArea = this.areas.filter(area => (isAreaText ? area.checkTypeTextArea() : !area.checkTypeTextArea()) && area.isFix == isFix)
      .length;
    while (this.areas.findIndex(area => area.name == `${areaName} ${countArea + 1}`) > -1) {
      countArea++;
    }
    return `${areaName} ${countArea + 1}`;
  }

  /**
   * get text default
   */
  private getTextDefault(): string {
    let areaTexts = this.areas.map(area => area.getArea().text);
    const arrays = this.TEXTS_DEFAULT.filter(text => {
      return !areaTexts.includes(text);
    });
    return arrays.length ? arrays[0] : this.TEXTS_DEFAULT[0];
  }

  /**
   * subscriber event when change position
   * @param area Area
   */
  public changePositionArea(area: AreaLED): void {
    if (area.posX < 0 || area.posY < 0 || area.width == null || area.height == null) {
      return;
    }
    area.posX = Math.ceil(area.posX / Constant.SCALE);
    area.posY = Math.ceil(area.posY / Constant.SCALE);
    area.width = Math.floor(area.width / Constant.SCALE);
    area.height = Math.floor(area.height / Constant.SCALE);

    this.rePreviewAreaWhenChangePosition(area);
  }

  /**
   * only text area
   * update duration change over
   * @param area Area
   * @param value
   */
  public updateDuration(area: AreaLED, value: number): void {
    let areaText = area as TextAreaLED;
    if (!value || value < this.MINIMUM_DURATION || value > this.MAXIMUM_DURATION) {
      this.isInvalid = true;
      return;
    }
    areaText.duration = value;
  }

  /**
   * only text area
   * update lead spacing
   * @param area Area
   * @param value
   */
  public changeLeadSpacing(area: AreaLED, value: number): void {
    let areaText = area as TextAreaLED;
    if (!value || value < this.MINIMUM_LEAD_SPACING || value > this.MAXIMUM_LEAD_SPACING) {
      this.isInvalid = true;
      return;
    }
    areaText.leadSpacing = value;
  }

  /**
   * only text area
   * update letter spacing
   * @param area Area
   * @param value
   */
  public changeLetterSpacing(area: AreaLED, value: number): void {
    let areaText = area as TextAreaLED;
    if (!value || value < this.MINIMUM_LETTER_SPACING || value > this.MAXIMUM_LETTER_SPACING) {
      this.isInvalid = true;
      return;
    }
    areaText.letterSpacing = value;
  }

  /**
   * only text area
   * update baseline-height
   * @param area Area
   * @param value
   */
  public changeBaselineHeight(area: AreaLED, value: number): void {
    let areaText = area as TextAreaLED;
    if (!value || value < this.MINIMUM_BASELINE_HEIGHT || value > this.MAXIMUM_BASELINE_HEIGHT) {
      this.isInvalid = true;
      return;
    }
    areaText.baselineHeight = value;
  }

  /**
   * only text area
   * update other delimiters
   * @param area Area
   * @param value
   */
  public changeOtherDelimiters(area: AreaLED, value: string): void {
    let areaText = area as TextAreaLED;
    if (value == '' || value.length > this.MAXIMUM_OTHER_DELIMITERS) {
      this.isInvalid = true;
      return;
    }
    areaText.otherDelimiters = value;
  }

  /**
   * double click to create area
   * @param event
   */
  @HostListener('dblclick', ['$event'])
  public doubleClick(event): void {
    if (event.target.id != Constant.CANVAS_LED_LAYOUT_ID) {
      return;
    }
    switch (this.toolEditTemplateSelected) {
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
        this.createAreaUsingDblClick(event, false, true);
        break;
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
        this.createAreaUsingDblClick(event, false, false);
        break;
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
        this.createAreaUsingDblClick(event, true, true);
        break;
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
        this.createAreaUsingDblClick(event, true, false);
        break;
      default:
        break;
    }
  }

  /**
   * create area
   * @param isAreaText
   * @param isFix
   * @param event
   */
  private createArea(isAreaText: boolean, isFix: boolean, event: any): void {
    let pointEndX = this.calculatePosition(event).x;
    let pointEndY = this.calculatePosition(event).y;

    // calculate position's area
    let pointMaxX = Math.max(this.pointStartX, pointEndX);
    pointMaxX = pointMaxX < this.templateSelected.width ? pointMaxX : this.templateSelected.width;

    let pointMinX = Math.min(this.pointStartX, pointEndX);
    pointMinX = pointMinX >= 0 ? pointMinX : 0;

    let pointMaxY = Math.max(this.pointStartY, pointEndY);
    pointMaxY = pointMaxY < this.templateSelected.height ? pointMaxY : this.templateSelected.height;

    let pointMinY = Math.min(this.pointStartY, pointEndY);
    pointMinY = pointMinY >= 0 ? pointMinY : 0;
    let canvasLayoutRealTime = document.getElementById(Constant.CANVAS_LED_LAYOUT_ID);
    this.drawLEDService.clearCanvas(canvasLayoutRealTime);
    if (pointMaxX == pointMinX || pointMaxY == pointMinY) {
      this.drawLEDService.clearCanvas(canvasLayoutRealTime);
      return;
    }
    // calculate width, height, posX, posY area
    const width = Math.ceil((pointMaxX - pointMinX) / Constant.SCALE);
    const height = Math.ceil((pointMaxY - pointMinY) / Constant.SCALE);
    const posX = Math.floor(pointMinX / Constant.SCALE);
    const posY = Math.floor(pointMinY / Constant.SCALE);
    this.handleAfterCreateArea(isAreaText, isFix, width, height, posX, posY);
  }

  /**
   * handle after create area
   * @param isAreaText
   * @param isFix
   * @param width
   * @param height
   * @param posX
   * @param posY
   */
  private handleAfterCreateArea(isAreaText: boolean, isFix: boolean, width: number, height: number, posX: number, posY: number): void {
    // get new name's area
    let nameArea = this.getNewNameArea(isAreaText, isFix);
    // set index's area
    let countArea = this.areas.length;
    let indexArea = countArea > 0 ? countArea + 1 : 1;
    let newArea = null;
    if (isAreaText) {
      if (!this.fontsDisplay.length) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('led-layout-editor.title-error'),
            text: this.translateService.instant('led-layout-editor.no-fonts')
          },
          autoFocus: true
        });
        return;
      }
      newArea = new TextAreaLED(nameArea, width, height, posX, posY, indexArea, isFix, this.getTextDefault());
      newArea.fontColor = Constant.FONT_COLOR_DEFAULT;
      newArea.backgroundColor = Constant.BACKGROUND_COLOR_DEFAULT;
      newArea.fontName = this.fontsDisplay[0].name;
      newArea.fontType = this.fontsDisplay[0].type;
      switch (this.templateGroupSelected.displayModel) {
        case DisplayModelEnum.FULL_COLOR:
          newArea.outlineColor = Constant.OUTLINE_COLOR_DEFAULT;
          newArea.outlineSize = OUTLINE[0];
          break;
        case DisplayModelEnum.AMBER:
          newArea.fontColor = Constant.FONT_COLOR_AMBER;
          break;
        default:
          break;
      }
      if (newArea.fontType == FontTypeEnum.PC_FONT) {
        newArea.fontSize = Constant.FONT_SIZE_PC_DEFAULT;
      } else {
        let index = this.fontNamesBmp.findIndex(font => font['name'] == newArea.getArea().fontName);
        if (index != -1) {
          newArea.getArea().fontSizesBitmap = this.fontNamesBmp[index]['sizes'];
          if (!newArea.fontSize) {
            newArea.fontSize = newArea.getArea().fontSizesBitmap[0];
          }
        }
      }
    } else {
      newArea = new PictureAreaLED(nameArea, width, height, posX, posY, indexArea, isFix);
      if (!newArea.getArea().isFix && this.indexWordGroups?.length) {
        newArea.indexWordGroupId = this.indexWordGroups[0].id;
        newArea['indexWordGroupName'] = this.indexWordGroups[0].name;
      } else {
        newArea.isFlipbook = false;
      }
    }

    // create canvas area
    this.drawLEDService.createCanvasArea(newArea, Constant.SCALE, this.renderer, this.canvasLEDContainer);
    this.areas.unshift(newArea);
    // set z-index area
    this.setZIndexArea();
    if (this.areas.length == Constant.MAX_AREAS_EDS) {
      this.toolEditTemplateSelected = EditTemplateToolsEnum.SELECT_AREA;
    }
    this.selectArea(newArea);
    this.drawLEDService.drawAreaLEDLayoutEditor(newArea, this.fontsBitmap);
  }

  /**
   * create area using double click
   * @param event
   * @param isAreaText
   * @param isFix
   */
  public createAreaUsingDblClick(event: any, isAreaText: boolean, isFix: boolean): void {
    // check number areas
    if (this.areas.length >= Constant.MAX_AREAS_EDS) {
      this.toolEditTemplateSelected = EditTemplateToolsEnum.SELECT_AREA;
      return;
    }
    // check coordinates double click
    var posX = this.calculatePosition(event).x;
    var posY = this.calculatePosition(event).y;
    var areasFocus = this.areas.filter(
      area =>
        posX >= area.posX * Constant.SCALE &&
        posX <= (area.posX + area.width) * Constant.SCALE &&
        posY >= area.posY * Constant.SCALE &&
        posY <= (area.posY + area.height) * Constant.SCALE
    );
    // return if there exists at least 1 area with selected
    if (areasFocus.length > 0) {
      return;
    }
    let x = 0;
    let y = 0;
    let w = this.templateSelected.width;
    let h = this.templateSelected.height;
    // calculate posX and width of new area
    this.areas.forEach(area => {
      if (area.posY * Constant.SCALE < posY && posY < (area.posY + area.height) * Constant.SCALE) {
        if (posX >= (area.posX + area.width) * Constant.SCALE) {
          x = Math.max(x, (area.posX + area.width) * Constant.SCALE);
        }
        if (posX <= area.posX * Constant.SCALE) {
          w = Math.min(w, area.posX * Constant.SCALE);
        }
      }
    });
    w = w - x;
    // calculate posY and height of new area
    this.areas.forEach(area => {
      if (
        (x < area.posX * Constant.SCALE && area.posX * Constant.SCALE < x + w) ||
        (x < (area.posX + area.width) * Constant.SCALE && (area.posX + area.width) * Constant.SCALE < x + w) ||
        (area.posX * Constant.SCALE <= x && x + w <= (area.posX + area.width) * Constant.SCALE)
      ) {
        if (posY >= (area.posY + area.height) * Constant.SCALE) {
          y = Math.max(y, (area.posY + area.height) * Constant.SCALE);
        }
        if (posY <= area.posY * Constant.SCALE) {
          h = Math.min(h, area.posY * Constant.SCALE);
        }
      }
    });
    let areaPosX = x / Constant.SCALE;
    let areaPosY = y / Constant.SCALE;
    let width = w / Constant.SCALE;
    let height = (h - y) / Constant.SCALE;
    this.handleAfterCreateArea(isAreaText, isFix, width, height, areaPosX, areaPosY);
  }

  /**
   * set z-index's area
   */
  private setZIndexArea(): void {
    let index = this.areas.length - 1;
    this.areas.forEach(area => {
      area.index = index;
      this.renderer.setStyle(area.canvas, 'z-index', index);
      index--;
    });
  }

  /**
   * select tool
   * @param tool EditTemplateToolsEnum
   * @param event
   */
  public selectTool(tool: EditTemplateToolsEnum, event?: any): void {
    this.toolEditTemplateSelected = tool;
    switch (tool) {
      case EditTemplateToolsEnum.SELECT_AREA:
        if (this.panzoom != undefined) {
          this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
        }
        this.renderer.setStyle(this.canvasLEDContainer.nativeElement, 'cursor', 'default');
        break;
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
        if (this.panzoom != undefined) {
          this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
        }
        this.renderer.setStyle(this.canvasLEDContainer.nativeElement, 'cursor', 'crosshair');
        break;
      case EditTemplateToolsEnum.ZOOM:
        if (this.panzoom != undefined) {
          this.panzoom.setOptions({ disablePan: false, disableZoom: false, force: true });
        }
        this.renderer.setStyle(this.canvasLEDContainer.nativeElement, 'cursor', 'move');
        break;
      case EditTemplateToolsEnum.PAN:
        if (this.panzoom != undefined) {
          this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
        }
        this.renderer.setStyle(this.canvasLEDContainer.nativeElement, 'cursor', 'grab');
        break;
      case EditTemplateToolsEnum.ALIGN: {
        if (this.panzoom != undefined) {
          this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
        }
        this.renderer.setStyle(this.canvasLEDContainer.nativeElement, 'cursor', 'default');
        this.isShowAlignTool = !this.isShowAlignTool;
        event.stopPropagation();
        break;
      }
      default:
        break;
    }
  }

  /**
   * mouse down subscriber
   * @param event
   */
  @HostListener('mousedown', ['$event'])
  public mouseDown(event: any): void {
    if (event.target.id != Constant.CANVAS_LED_LAYOUT_ID || this.isInvalid) {
      return;
    }
    this.isMouseDown = true;
    this.pointDownFirst = event;
    switch (this.toolEditTemplateSelected) {
      case EditTemplateToolsEnum.SELECT_AREA:
        // select area
        this.selectAreaUsingMouse(event);
        break;
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
        this.pointStartX = this.calculatePosition(event).x;
        this.pointStartY = this.calculatePosition(event).y;
        break;
      case EditTemplateToolsEnum.PAN:
        // pan area
        this.panFunction(event);
        break;
      default:
        break;
    }
  }

  /**
   * mouse up subscriber
   * @param event
   */
  @HostListener('mouseup', ['$event'])
  public mouseUp(event: any): void {
    if (!this.isMouseDown) {
      return;
    }
    this.isMouseDown = false;
    // clear canvas
    var canvas: any = document.getElementById(Constant.CANVAS_LED_LAYOUT_ID);
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
    this.pointDownLast = event;

    switch (this.toolEditTemplateSelected) {
      case EditTemplateToolsEnum.SELECT_AREA:
        // reset resize
        if (this.areaResize) {
          this.areaResize = undefined;
          this.selectArea(this.areaSelected);
        }
        this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
        break;
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
        this.createArea(true, true, event);
        break;
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
        this.createArea(true, false, event);
        break;
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
        this.createArea(false, true, event);
        break;
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
        this.createArea(false, false, event);
        break;
      case EditTemplateToolsEnum.PAN:
        // end pan area
        this.areaSelected.posX = Math.round(this.areaSelected.posX);
        this.areaSelected.posY = Math.round(this.areaSelected.posY);
        this.selectArea(this.areaSelected);
        this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
        this.pointDragStart = null;
        this.renderer.setStyle(this.canvasLEDContainer.nativeElement, 'cursor', 'grab');
        break;
      default:
        break;
    }
  }

  /**
   * mouse move subscriber
   * @param event
   */
  @HostListener('mousemove', ['$event'])
  public mouseMove(event: any): void {
    if (!this.isMouseDown) {
      if (this.isShowedTemplateEditor && event.target.id == Constant.CANVAS_LED_LAYOUT_ID) {
        this.changeCursorIcon(event);
      }
      return;
    }
    switch (this.toolEditTemplateSelected) {
      case EditTemplateToolsEnum.SELECT_AREA:
        // resize area
        if (this.areaResize) {
          this.resizeArea(event);
          return;
        }
        break;
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
        this.createAreaUsingMouse(event, this.toolEditTemplateSelected);
        break;
      case EditTemplateToolsEnum.PAN:
        // pan area
        this.renderer.setStyle(this.canvasLEDContainer.nativeElement, 'cursor', 'grabbing');
        this.panArea(event);
        break;
      default:
        break;
    }
  }

  /**
   * mouse wheel
   * @param event
   */
  @HostListener('mousewheel', ['$event'])
  public mouseWheel(event: any): void {
    if (event.target.id != Constant.CANVAS_LED_LAYOUT_ID) {
      return;
    }
    if (this.toolEditTemplateSelected == EditTemplateToolsEnum.ZOOM) {
      let delta = event.wheelDelta;
      var position = { x: event.x, y: event.y };
      // Get style of element
      var style = window.getComputedStyle(this.canvasLEDContainer.nativeElement);
      var matrix = new WebKitCSSMatrix(style.webkitTransform);
      position.x =
        this.canvasLEDContainer.nativeElement.offsetLeft +
        matrix.m41 +
        this.divPreviewLED.nativeElement.offsetLeft -
        this.divPreviewLED.nativeElement.offsetParent.scrollLeft +
        2;
      position.y =
        this.canvasLEDContainer.nativeElement.offsetTop +
        matrix.m42 +
        this.divPreviewLED.nativeElement.offsetTop +
        69 -
        this.divPreviewLED.nativeElement.offsetParent.scrollTop -
        this.divPreviewLED.nativeElement.scrollTop -
        15 +
        1;
      if (delta > 0) {
        this.panzoom.zoomToPoint(this.scalePreview.value + 0.05, { clientX: position.x, clientY: position.y });
      } else {
        this.panzoom.zoomToPoint(this.scalePreview.value - 0.05, { clientX: position.x, clientY: position.y });
      }
      this.scalePreview.value = this.panzoom.getScale();
      var scaleName = String(Math.ceil(this.scalePreview.value * 100));
      this.scalePreview.name = scaleName;
    }
  }

  /**
   * resize area
   * @param event
   */
  private resizeArea(event: any): void {
    var positionPreviewTemplate = this.calculatePosition(event);
    var lastX = positionPreviewTemplate.x;
    var lastY = positionPreviewTemplate.y;
    lastX = lastX < 0 ? 0 : lastX;
    lastY = lastY < 0 ? 0 : lastY;
    var posXResult = this.areaResize.area.getArea().posX * Constant.SCALE;
    var posYResult = this.areaResize.area.getArea().posY * Constant.SCALE;
    var widthResult = this.areaResize.area.getArea().width * Constant.SCALE;
    var heightResult = this.areaResize.area.getArea().height * Constant.SCALE;
    var posXOld;
    var posYOld;
    switch (this.areaResize.type) {
      case TypeResizeAreaEnum.CHANGE_X_Y_W_H:
        posXOld = posXResult;
        posYOld = posYResult;
        posXResult = lastX;
        posYResult = lastY;
        widthResult += posXOld - lastX;
        heightResult += posYOld - lastY;
        break;
      case TypeResizeAreaEnum.CHANGE_X_W:
        posXOld = posXResult;
        posXResult = lastX;
        widthResult += posXOld - lastX;
        break;
      case TypeResizeAreaEnum.CHANGE_X_W_H:
        posXOld = posXResult;
        posYOld = posYResult;
        posXResult = lastX;
        widthResult += posXOld - lastX;
        heightResult = lastY - posYOld;
        break;
      case TypeResizeAreaEnum.CHANGE_H:
        heightResult = lastY - posYResult;
        break;
      case TypeResizeAreaEnum.CHANGE_W_H:
        posXOld = posXResult;
        posYOld = posYResult;
        widthResult = lastX - posXOld;
        heightResult = lastY - posYOld;
        break;
      case TypeResizeAreaEnum.CHANGE_W:
        widthResult = lastX - posXResult;
        break;
      case TypeResizeAreaEnum.CHANGE_Y_W_H:
        posXOld = posXResult;
        posYOld = posYResult;
        posYResult = lastY;
        widthResult = lastX - posXOld;
        heightResult += posYOld - lastY;
        break;
      case TypeResizeAreaEnum.CHANGE_Y_H:
        posYOld = posYResult;
        posYResult = lastY;
        heightResult += posYOld - lastY;
        break;

      default:
        break;
    }
    // validate size, position area
    if (posXResult < 0 || posXResult > (this.areaResize.area.getArea().posX + this.areaResize.area.getArea().width) * Constant.SCALE) {
      return;
    }
    if (posYResult < 0 || posYResult > (this.areaResize.area.getArea().posY + this.areaResize.area.getArea().height) * Constant.SCALE) {
      return;
    }
    if (widthResult <= 0) {
      widthResult = 0;
    }
    if (heightResult <= 0) {
      heightResult = 0;
    }
    if (posXResult + widthResult > this.templateSelected.width) {
      widthResult = this.templateSelected.width - this.areaResize.area.getArea().posX * Constant.SCALE;
    }
    if (posYResult + heightResult > this.templateSelected.height) {
      heightResult = this.templateSelected.height - this.areaResize.area.getArea().posY * Constant.SCALE;
    }
    this.areaResize.area.getArea().posX = posXResult;
    this.areaResize.area.getArea().posY = posYResult;
    this.areaResize.area.getArea().width = widthResult;
    this.areaResize.area.getArea().height = heightResult;

    this.changePositionArea(this.areaResize.area.getArea());
  }

  /**
   * pan area
   * @param event
   */
  private panArea(event: any) {
    if (!this.isMouseDown || this.pointDragStart == null || !this.areaSelected) {
      return;
    }
    let currentPosX = this.areaSelected.posX * Constant.SCALE + Math.ceil((event.x - this.pointDragStart.x) / this.scalePreview.value);
    let currentPosY = this.areaSelected.posY * Constant.SCALE + Math.ceil((event.y - this.pointDragStart.y) / this.scalePreview.value);

    this.areaSelected.posX = currentPosX;
    this.areaSelected.posY = currentPosY;

    if (this.areaSelected.posX < 0) {
      this.areaSelected.posX = 0;
    }
    if (this.areaSelected.posX > this.templateSelected.width - this.areaSelected.width * Constant.SCALE) {
      this.areaSelected.posX = this.templateSelected.width - this.areaSelected.width * Constant.SCALE;
    }
    if (this.areaSelected.posY < 0) {
      this.areaSelected.posY = 0;
    }
    if (this.areaSelected.posY > this.templateSelected.height - this.areaSelected.height * Constant.SCALE) {
      this.areaSelected.posY = this.templateSelected.height - this.areaSelected.height * Constant.SCALE;
    }

    this.areaSelected.posX = this.areaSelected.posX / Constant.SCALE;
    this.areaSelected.posY = this.areaSelected.posY / Constant.SCALE;
    this.rePreviewAreaWhenChangePosition(this.areaSelected);
    this.pointDragStart = event;
  }

  /**
   * change cursor icon
   * @param event
   */
  private changeCursorIcon(event: any): void {
    if (this.toolEditTemplateSelected != EditTemplateToolsEnum.SELECT_AREA) {
      return;
    }
    var position = this.calculatePosition(event);
    var posX = position.x;
    var posY = position.y;
    if (posX < 0 || posX > this.templateSelected.width || posY < 0 || posY > this.templateSelected.height) {
      return;
    }
    this.areaResize = this.getAreaCanResize(this.areaSelected, posX, posY);
    if (!this.areaResize) {
      this.areas.slice().forEach(area => {
        var areaResize = this.getAreaCanResize(area, posX, posY);
        if (areaResize) {
          this.areaResize = areaResize;
        }
      });
    }
    if (this.areaResize) {
      this.renderer.setStyle(this.canvasLEDContainer.nativeElement, 'cursor', this.areaResize.cursor);
    } else {
      this.renderer.setStyle(this.canvasLEDContainer.nativeElement, 'cursor', 'default');
    }
  }

  /**
   * get area can resize
   * @param area Area
   * @param currentPosX
   * @param currentPosY
   */
  private getAreaCanResize(area: AreaLED, currentPosX: number, currentPosY: number): any {
    var areaResize: { area: AreaLED; cursor: string; type: TypeResizeAreaEnum };
    if (!area) {
      return areaResize;
    }
    if (
      currentPosX >= area.posX * Constant.SCALE - 1 &&
      currentPosX <= area.posX * Constant.SCALE + 6 &&
      currentPosY >= area.posY * Constant.SCALE - 1 &&
      currentPosY <= area.posY * Constant.SCALE + 6
    ) {
      areaResize = { area: area, cursor: 'nw-resize', type: TypeResizeAreaEnum.CHANGE_X_Y_W_H };
    } else if (
      currentPosX >= area.posX * Constant.SCALE - 1 &&
      currentPosX <= area.posX * Constant.SCALE + 6 &&
      currentPosY >= (area.posY + area.height) * Constant.SCALE - 1 &&
      currentPosY <= (area.posY + area.height) * Constant.SCALE + 6
    ) {
      areaResize = { area: area, cursor: 'ne-resize', type: TypeResizeAreaEnum.CHANGE_X_W_H };
    } else if (
      currentPosX >= (area.posX + area.width) * Constant.SCALE - 6 &&
      currentPosX <= (area.posX + area.width) * Constant.SCALE + 1 &&
      currentPosY >= (area.posY + area.height) * Constant.SCALE - 6 &&
      currentPosY <= (area.posY + area.height) * Constant.SCALE + 1
    ) {
      areaResize = { area: area, cursor: 'nw-resize', type: TypeResizeAreaEnum.CHANGE_W_H };
    } else if (
      currentPosX >= (area.posX + area.width) * Constant.SCALE - 6 &&
      currentPosX <= (area.posX + area.width) * Constant.SCALE + 1 &&
      currentPosY >= area.posY * Constant.SCALE - 1 &&
      currentPosY <= area.posY * Constant.SCALE + 6
    ) {
      areaResize = { area: area, cursor: 'ne-resize', type: TypeResizeAreaEnum.CHANGE_Y_W_H };
    } else if (
      currentPosX >= area.posX * Constant.SCALE - 1 &&
      currentPosX <= area.posX * Constant.SCALE + 6 &&
      currentPosY >= area.posY * Constant.SCALE + 6 &&
      currentPosY <= (area.posY + area.height) * Constant.SCALE
    ) {
      areaResize = { area: area, cursor: 'e-resize', type: TypeResizeAreaEnum.CHANGE_X_W };
    } else if (
      currentPosY >= (area.posY + area.height) * Constant.SCALE - 6 &&
      currentPosY <= (area.posY + area.height) * Constant.SCALE + 1 &&
      currentPosX >= area.posX * Constant.SCALE + 6 &&
      currentPosX <= (area.posX + area.width) * Constant.SCALE - 6
    ) {
      areaResize = { area: area, cursor: 'n-resize', type: TypeResizeAreaEnum.CHANGE_H };
    } else if (
      currentPosX >= (area.posX + area.width) * Constant.SCALE - 6 &&
      currentPosX <= (area.posX + area.width) * Constant.SCALE + 1 &&
      currentPosY >= area.posY * Constant.SCALE + 6 &&
      currentPosY <= (area.posY + area.height) * Constant.SCALE - 6
    ) {
      areaResize = { area: area, cursor: 'e-resize', type: TypeResizeAreaEnum.CHANGE_W };
    } else if (
      currentPosY >= area.posY * Constant.SCALE - 1 &&
      currentPosY <= area.posY * Constant.SCALE + 6 &&
      currentPosX >= area.posX * Constant.SCALE + 6 &&
      currentPosX <= (area.posX + area.width) * Constant.SCALE - 6
    ) {
      areaResize = { area: area, cursor: 'n-resize', type: TypeResizeAreaEnum.CHANGE_Y_H };
    }
    return areaResize;
  }

  /**
   * pan area using mouse
   * @param event
   */
  private panFunction(event: any): void {
    this.selectAreaUsingMouse(event);
    var posX = this.calculatePosition(event).x;
    var posY = this.calculatePosition(event).y;
    if (
      posX < this.areaSelected.posX * Constant.SCALE ||
      posX > (this.areaSelected.posX + this.areaSelected.width) * Constant.SCALE ||
      posY < this.areaSelected.posY * Constant.SCALE ||
      posY > (this.areaSelected.posY + this.areaSelected.height) * Constant.SCALE
    ) {
      this.isMouseDown = false;
      return;
    }
    this.pointDragStart = event;
  }

  /**
   * set border area selected
   * @param area
   */
  private setBorderAreaSelected(area: AreaLED): void {
    let borderStyle = area.isFix ? this.BORDER_STYLE_FIX : this.BORDER_STYLE_LINK;
    this.renderer.setStyle(area.canvas, 'border', `${Constant.BORDER_WIDTH_AREA}px ` + `${borderStyle} ` + 'red');
  }

  /**
   * set border area no selected
   * @param area
   */
  private setBorderAreaNoSelected(area: AreaLED): void {
    let color = area.checkTypeTextArea() ? this.BORDER_COLOR_TEXT : this.BORDER_COLOR_PICTURE;
    let borderStyle = area.isFix ? this.BORDER_STYLE_FIX : this.BORDER_STYLE_LINK;
    this.renderer.setStyle(area.canvas, 'border', `${Constant.BORDER_WIDTH_AREA}px ` + `${borderStyle} ` + color);
  }

  /**
   * select area on preview using mouse
   * @param event
   * @param isPan
   */
  public selectAreaUsingMouse(event: any, isPan?: boolean) {
    if (this.isShowColorPicker || this.isInvalid) {
      return;
    }
    var posX = this.calculatePosition(event).x;
    var posY = this.calculatePosition(event).y;
    var areasFocus = this.areas.filter(
      area =>
        posX >= area.posX * Constant.SCALE &&
        posX <= (area.posX + area.width) * Constant.SCALE &&
        posY >= area.posY * Constant.SCALE &&
        posY <= (area.posY + area.height) * Constant.SCALE
    );
    if (areasFocus.length <= 0) {
      return;
    }
    areasFocus = areasFocus.sort((area1, area2) => {
      return area1.index - area2.index;
    });
    var area = areasFocus[areasFocus.length - 1];
    this.setBorderAreaSelected(area);
    if (this.areaSelected) {
      // choose areas using Ctrl + mouse down
      if (event.ctrlKey && !isPan) {
        area.isSelected = !area.isSelected;
        // push element into areasSelected if area is selected
        if (area.isSelected) {
          this.areasSelected.push(area);
          if (this.isEditText) {
            this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
            this.isEditText = false;
          }
          this.areaSelected = area;
        } else {
          if (this.areasSelected.length == 1) {
            area.isSelected = true;
            return;
          }
          // reset border
          this.resetBorderArea(area);
          // remove area from areasSelected
          this.areasSelected.forEach((item, index) => {
            if (area == item) {
              this.areasSelected.splice(index, 1);
              this.areaSelected = this.areasSelected[this.areasSelected.length - 1];
            }
          });
        }
      } else {
        if (this.areaSelected == area && this.areasSelected.length == 1) {
          return;
        }
        // reset the previously selected areas
        this.getSelectedAreas().forEach(areaSelected => {
          if (areaSelected != area) {
            areaSelected.isSelected = false;
            this.resetBorderArea(areaSelected);
          }
        });
        // reset array areas selected
        this.areasSelected = new Array<AreaLED>();
        area.isSelected = true;
        this.areasSelected.push(area);
        if (this.isEditText) {
          this.drawLEDService.drawAreaLEDLayoutEditor(this.areaSelected, this.fontsBitmap);
          this.isEditText = false;
        }
        this.selectArea(area, true);
      }
    } else {
      area.isSelected = true;
      this.areasSelected.push(area);
      this.selectArea(area, true);
    }
    // assign old value position
    this.oldValueX = this.areaSelected.posX;
    this.oldValueY = this.areaSelected.posY;
    this.oldValueW = this.areaSelected.width;
    this.oldValueH = this.areaSelected.height;
  }

  /**
   * reset border area
   * @param area
   */
  private resetBorderArea(area: AreaLED): void {
    if (!this.isClearAllBorderCanvas) {
      this.setBorderAreaNoSelected(area);
    } else {
      this.renderer.removeStyle(area.canvas, 'border');
    }
  }

  /**
   * reset border canvas all areas
   */
  private resetBorderCanvasAreas(): void {
    this.areas.forEach(area => {
      area.isSelected = false;
      this.setBorderAreaNoSelected(area);
    });
  }

  /**
   * re-preview area when change position
   * @param area AreaLED
   */
  private rePreviewAreaWhenChangePosition(area: AreaLED): void {
    this.renderer.setStyle(area.canvas, 'visibility', area.isHidden ? 'hidden' : 'visible');
    this.renderer.setStyle(area.canvas, 'left', area.posX * Constant.SCALE + 'px');
    this.renderer.setStyle(area.canvas, 'top', area.posY * Constant.SCALE + 'px');
    this.renderer.setStyle(area.canvas, 'width', area.width * Constant.SCALE + 'px');
    this.renderer.setStyle(area.canvas, 'height', area.height * Constant.SCALE + 'px');
    this.renderer.setAttribute(area.canvas, 'width', area.width * Constant.SCALE + '');
    this.renderer.setAttribute(area.canvas, 'height', area.height * Constant.SCALE + '');
  }

  /**
   * calculate posX, posY when transform change
   * @param event
   */
  private calculatePosition(event: any): any {
    var position = { x: 0, y: 0 };

    // Get style of element
    var style = window.getComputedStyle(this.canvasLEDContainer.nativeElement);
    var matrix = new WebKitCSSMatrix(style.webkitTransform);

    position.x =
      2 +
      this.offsetLeftCurrent +
      this.canvasLEDContainer.nativeElement.offsetLeft +
      matrix.m41 +
      this.divPreviewLED.nativeElement.offsetLeft -
      this.divPreviewLED.nativeElement.offsetParent.scrollLeft;
    position.y =
      2 +
      this.offsetTopCurrent +
      this.canvasLEDContainer.nativeElement.offsetTop +
      matrix.m42 +
      this.divPreviewLED.nativeElement.offsetTop +
      69 -
      this.divPreviewLED.nativeElement.offsetParent.scrollTop -
      this.divPreviewLED.nativeElement.scrollTop -
      15;
    position.x = Math.ceil((event.x - position.x) / this.scalePreview.value);
    position.y = Math.ceil((event.y - position.y) / this.scalePreview.value);
    return position;
  }

  /**
   * create border when move mouse to create area
   * @param event
   * @param tool EditTemplateToolsEnum
   */
  private createAreaUsingMouse(event: any, tool: EditTemplateToolsEnum): void {
    if (this.areas.length >= Constant.MAX_AREAS_EDS) {
      this.toolEditTemplateSelected = EditTemplateToolsEnum.SELECT_AREA;
      return;
    }
    if (!this.isMouseDown || (typeof this.endPoint != 'undefined' && this.endPoint.x == event.x && this.endPoint.y == event.y)) {
      return;
    }
    let color: string = '';
    let isFix: boolean;
    switch (tool) {
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
        color = this.BORDER_COLOR_TEXT;
        isFix = true;
        break;
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
        color = this.BORDER_COLOR_TEXT;
        isFix = false;
        break;
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
        color = this.BORDER_COLOR_PICTURE;
        isFix = true;
        break;
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
        color = this.BORDER_COLOR_PICTURE;
        isFix = false;
        break;
      default:
        break;
    }
    this.endPoint = event;
    let lastPointX = this.calculatePosition(event).x;
    let lastPointY = this.calculatePosition(event).y;
    let pointMaxX = Math.max(this.pointStartX, lastPointX);
    let pointMaxY = Math.max(this.pointStartY, lastPointY);
    let pointMinX = Math.min(this.pointStartX, lastPointX);
    let pointMinY = Math.min(this.pointStartY, lastPointY);
    let canvasLayoutRealTime = document.getElementById(Constant.CANVAS_LED_LAYOUT_ID);
    if (color != '') {
      this.drawLEDService.drawBorderArea(pointMaxX, pointMaxY, pointMinX, pointMinY, canvasLayoutRealTime, color, isFix);
    }
  }

  /**
   * save as template
   */
  private saveAsTemplate(): void {
    // validate reference source
    if (!this.validateReferenceSource()) {
      return;
    }
    // show popup Save as Template
    this.dialogService.showDialog(
      DialogTemplateLedComponent,
      {
        data: {
          title: this.translateService.instant('led-layout-editor.save-as-template'),
          template: Object.assign({}, this.templateSelected),
          templatesOfGroup: this.templatesOfGroup,
          templateGroup: this.templateGroupSelected
        }
      },
      result => {
        if (!result) {
          return;
        }
        let templateNew = new TemplateLED(
          result.name,
          this.templateSelected.width,
          this.templateSelected.height,
          this.templateSelected.templateGroupId,
          this.templateSelected.templateGroupName
        );
        let areas = _.cloneDeep(this.areas);
        areas.forEach(area => (area.id = null));
        // save new template
        this.templateLedService.saveAsTemplate(templateNew, Helper.convertDataAreasLEDBackward(areas)).subscribe(
          (template: TemplateLED) => {
            this.isChangedData = false;
            this.templateSelected = undefined;
            this.templatesOfGroup.push(template);
            this.changeTemplateDetail(template);
          },
          () => {
            this.showDialogError();
            return;
          }
        );
      }
    );
  }

  /**
   * validate reference source
   * @returns
   */
  private validateReferenceSource(): boolean {
    let linkAreas = this.areas.filter(area => !area.isFix);
    let linkAreasText = linkAreas.filter(area => area.checkTypeTextArea());
    let linkAreasPicture = linkAreas.filter(area => !area.checkTypeTextArea());
    if (!this.indexWordGroups.length) {
      // validate link picture area
      let areaPicture = linkAreasPicture.find(area => area.getArea().linkReferenceData == LinkDataPictureLEDEnum.INDEX_WORD);
      if (areaPicture) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('led-layout-editor.title-error'),
            text: Helper.formatString(this.translateService.instant('led-layout-editor.invalid-reference-source'), areaPicture.name)
          },
          autoFocus: true
        });
        return false;
      }
      // validate link text area
      let areaText = linkAreasText.find(area => area.getArea().linkReferenceData == LinkDataTextLEDEnum.INDEX_WORD);
      if (areaText) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('led-layout-editor.title-error'),
            text: Helper.formatString(this.translateService.instant('led-layout-editor.invalid-reference-source'), areaText.name)
          },
          autoFocus: true
        });
        return false;
      }
    }
    return true;
  }

  /**
   * allow drag - drop media from Media Manager
   * @param event
   */
  public allowDrop(event: any): void {
    var posX = this.calculatePosition(event).x;
    var posY = this.calculatePosition(event).y;
    var areasFocus = this.areas.filter(
      area =>
        !area.checkTypeTextArea() &&
        area.isFix &&
        posX >= area.posX * Constant.SCALE &&
        posX <= (area.posX + area.width) * Constant.SCALE &&
        posY >= area.posY * Constant.SCALE &&
        posY <= (area.posY + area.height) * Constant.SCALE
    );
    // if not found area then return
    if (areasFocus.length <= 0) {
      return;
    }

    // reset border area is not focus
    let areaFocus = areasFocus[areasFocus.length - 1];
    this.areas.forEach(area => {
      if (area.symbol == areaFocus.symbol) {
        return;
      }
      area.isSelected = false;
      this.resetBorderArea(area);
    });

    // set border area focus
    this.setBorderAreaSelected(areaFocus);
    this.areaSelected = areaFocus;
    event.preventDefault();
  }

  /**
   * receive data from Media Manager
   * @param event
   */
  public dropMedia(event: any): void {
    // return if drop media from stationContent folder
    if (
      JSON.parse(event.dataTransfer.getData(Constant.IS_MEDIA_IN_STATION_CONTENT_FOLDER)) ||
      JSON.parse(event.dataTransfer.getData(Constant.IS_MEDIA_IN_LCD_LAYOUT_EDITOR)) ||
      JSON.parse(event.dataTransfer.getData(Constant.FOLDER_INDEX_WORD_EDITOR))
    ) {
      return;
    }
    event.preventDefault();
    // receive data from Media Manager
    let data = event.dataTransfer.getData(Constant.MEDIA_VALUE);
    if (!data) {
      return;
    }
    let mediaData = JSON.parse(data);
    let media = Helper.convertMediaData(mediaData);
    // validate data media
    if (media.type != TypeMediaFileEnum.BMP) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('led-layout-editor.title-error'),
          text: this.translateService.instant('led-layout-editor.file-not-support')
        },
        autoFocus: true
      });
      return;
    }
    // validate bitmap color
    this.mediaService.validateImageBitmap(mediaData.id).subscribe(data => {
      const valueCheck = +data;
      if (
        (valueCheck != Constant.VALUE_BITMAP_MONOCHROME && valueCheck != Constant.VALUE_BITMAP_256_COLOR) ||
        (valueCheck == Constant.VALUE_BITMAP_MONOCHROME && this.templateGroupSelected.displayModel == DisplayModelEnum.FULL_COLOR) ||
        (valueCheck == Constant.VALUE_BITMAP_256_COLOR && this.templateGroupSelected.displayModel != DisplayModelEnum.FULL_COLOR)
      ) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('led-layout-editor.title-error'),
            text: this.translateService.instant('led-layout-editor.file-not-support')
          },
          autoFocus: true
        });
        return;
      }
      (<PictureAreaLED>this.areaSelected).media = mediaData;
      this.drawLEDService.drawAreaPictureLEDLayout(this.areaSelected);
    });
  }

  /**
   * change resize area is PictureArea
   * @param area AreaLED
   */
  public changeResizePictureArea(area: AreaLED): void {
    let areaPicture = area as PictureAreaLED;
    this.drawLEDService.drawAreaPictureLEDLayout(areaPicture);
  }

  /**
   * change setting direction
   */
  public changeSettingDirection(): void {
    // set pauseTime default
    if (
      !(this.areaSelected.scrollDirection == ScrollDirectionsEnum.LEFT || this.areaSelected.scrollDirection == ScrollDirectionsEnum.RIGHT)
    ) {
      this.areaSelected.pauseTime = this.PAUSE_TIME_DEFAULT;
    }
    if (!this.areaSelected.checkTypeTextArea()) {
      return;
    }
    // set scroll of adjacent area
    let index = this.areas.findIndex(area => area.name == this.areaSelected.getArea().linkWith);
    if (index != -1) {
      this.setScrollOfAdjacentArea(this.areas[index]);
      this.hideChangeOver(this.areas[index]);
    }
  }

  /**
   * toggle flip book
   */
  public toggleFlipbook(): void {
    this.areaSelected.getArea().isFlipbook = !this.areaSelected.getArea().isFlipbook;
    this.areaSelected.getArea().scrollSpeed = SpeedEnum.LOW;
    if (this.areaSelected.getArea().isFlipbook) {
      this.areaSelected.getArea().scrollStatus = ScrollStatusEnum.OFF;
      this.areaSelected.getArea().objectFit = ObjectFitEnum.FILL;
      this.changeResizePictureArea(this.areaSelected);
    }
  }

  /**
   * on drop area
   * @param event
   */
  public onDropArea(event: CdkDragDrop<any>): any {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    // setZIndexArea
    this.setZIndexArea();
  }
}

/**
 * enum ElementInputLED
 */
export enum ElementInputLED {
  X_INPUT = 'xInputLED',
  Y_INPUT = 'yInputLED',
  WIDTH_INPUT = 'widthInputLED',
  HEIGHT_INPUT = 'heightInputLED',
  TEXT_INPUT = 'textInputLED',
  FONT_SIZE_INPUT = 'fontSizeInputLED',
  SCROLL_SPEED_INPUT = 'scrollSpeedInputLED',
  SCALE_PREVIEW_INPUT = 'scalePreviewInputLED',
  PAUSE_TIME_INPUT = 'pauseTimeInputLED',
  LEAD_SPACING_INPUT = 'leadSpacingInputLED',
  LETTER_SPACING_INPUT = 'letterSpacingInputLED',
  BASELINE_HEIGHT_INPUT = 'baselineHeightInputLED',
  DURATION_INPUT = 'durationInputLED',
  OTHER_DELIMITERS_INPUT = 'otherDelimitersInputLED'
}
