import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import {
  Constant,
  ErrorEnum,
  FIELD_COMPONENT,
  MODULE_NAME,
  NewsContentSourceEnum,
  ObjectFitEnum,
  WeatherContentSourceEnum
} from 'app/config/constants';
import { DialogChangeTemplateForExternalContentComponent } from 'app/dialog/dialog-change-template-for-external-content/dialog-change-template-for-external-content.component';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Area } from 'app/model/entity/area';
import { Common } from 'app/model/entity/common';
import { IndexWordGroup } from 'app/model/entity/index-word-group';
import { NewsContent } from 'app/model/entity/news-content';
import { PictureArea } from 'app/model/entity/picture-area';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import { WeatherContent } from 'app/model/entity/weather-content';
import { WeatherContentDetail } from 'app/model/entity/weather-content-detail';
import { SaveExternalContentManagerStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { DrawService } from 'app/service/draw.service';
import { ExecutingService } from 'app/service/executing.service';
import { IndexWordGroupService } from 'app/service/index-word-group.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { MunicipalService } from 'app/service/municipal.service';
import { NewsContentDetailService } from 'app/service/news-content-detail.service';
import { NewsContentService } from 'app/service/news-content.service';
import { WeatherContentDetailService } from 'app/service/weather-content-detail.service';
import { WeatherContentService } from 'app/service/weather-content.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject, timer } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { SaveNewsContentTabStateAction, SaveWeatherContentTabStateAction } from './ngrx/content-action';

@Component({
  selector: 'external-content-manager',
  templateUrl: './external-content-manager.component.html',
  styleUrls: ['./external-content-manager.component.scss']
})
export class ExternalContentManagerComponent implements OnInit, OnDestroy {
  //#region public global var
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  WeatherContentSourceEnum = WeatherContentSourceEnum;
  NewsContentSourceEnum = NewsContentSourceEnum;
  public readonly MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME = 64;
  public readonly MAX_LENGTH_MUNICIPAL_CODE = 10;
  public readonly MIN_PAGE_COUNTS = 1;
  public readonly MAX_PAGE_COUNTS = 10;
  public readonly MIN_DURATION = 1;
  public readonly MAX_DURATION = 60;
  public readonly MIN_FORECAST_PARAM = 1;
  public readonly MAX_FORECAST_PARAM = 48;
  public readonly linkTextWeatherSourceOptions = [
    { id: WeatherContentSourceEnum.WEATHER_LOCATION, name: 'weather_location' },
    { id: WeatherContentSourceEnum.DATE_TIME_MM_DD, name: 'date_time (MM/DD)' },
    { id: WeatherContentSourceEnum.DATE_TIME_HH, name: 'date_time (HH)' },
    { id: WeatherContentSourceEnum.TEMP, name: 'temp' }
  ];
  public readonly linkPictureWeatherSourceOptions = [{ id: WeatherContentSourceEnum.WEATHER_NO, name: 'weather_no' }];
  public readonly newsSourceOptions = [
    { id: NewsContentSourceEnum.TITLE, name: 'Title' },
    { id: NewsContentSourceEnum.CONTENT_TYPE, name: 'Content type="text/plan"' }
  ];
  public indexWordGroups: Array<IndexWordGroup>;
  public contents: Array<any>;
  public isActiveNewsTab: boolean;
  public isEnlargePreview: boolean = false;
  public isChangeMunicipalCode: boolean = false;
  public contentSelected: any;
  public contentDetailSelected: any;
  public areas: Array<any>;
  public isReloadContentData: boolean = false;
  public isPlayPreview: boolean = false;
  public newsCurrentPage: number = 1;
  public newsTotalPages: number;
  public areaDisabledOptions: Array<Area>;
  //#endregion

  //#region private global var
  private readonly ENTER_KEY_CODE = 13;
  private readonly highlightAreaPreviewSubject = new Subject();
  private readonly resetTimerPreviewSubject = new Subject();
  private readonly pausePreviewSubject = new Subject();
  private readonly propertiesName = {
    content: 'Content',
    contentOutputFileName: 'Content Output File Name',
    municipalCode: 'Municipal Code',
    pageCounts: 'Page Counts',
    duration: 'Duration',
    forecastParam: 'Forecast Param'
  };
  private subscriptions: Array<Subscription> = new Array<Subscription>();
  private inputFocusCurrent: any;
  private contentCloneSelected: any;
  private stateOfComponent: {
    isChangeLayout: boolean;
    indexWordGroups: Array<IndexWordGroup>;
    contents: Array<any>;
    isActiveNewsTab: boolean;
    isEnlargePreview: boolean;
    isChangeMunicipalCode: boolean;
    contentSelected: any;
    contentDetailSelected: any;
    areas: Array<any>;
    newsCurrentPage: number;
    areaDisabledOptions: Array<Area>;
    inputFocusCurrent: any;
    contentCloneSelected: any;
    isChangedData: boolean;
    isChangedNewsTab: boolean;
    isChangedWeatherTab: boolean;
  };
  private newsContentTabStateOfComponent: {
    isChangeTab: boolean;
    contents: Array<any>;
    contentSelected: any;
    contentDetailSelected: any;
    areas: Array<any>;
    newsCurrentPage: number;
    areaDisabledOptions: Array<Area>;
    inputFocusCurrent: any;
    contentCloneSelected: any;
  };
  private weatherContentTabStateOfComponent: {
    isChangeTab: boolean;
    indexWordGroups: Array<IndexWordGroup>;
    contents: Array<any>;
    isChangeMunicipalCode: boolean;
    contentSelected: any;
    contentDetailSelected: any;
    areas: Array<any>;
    areaDisabledOptions: Array<Area>;
    inputFocusCurrent: any;
    contentCloneSelected: any;
  };
  private isChangedData: boolean;
  private isChangedNewsTab: boolean;
  /**
   * Common object
   */
  private commonObject: Common;
  public isChangedWeatherTab: boolean;
  /**
   * keys code
   */
  private keyCodeMap = {
    17: false, // Ctrl
    18: false, // Alt
    83: false // S
  };
  //#endregion

  //#region view child element
  @ViewChild('selectElement', { static: false })
  selectElement: ElementRef;

  @ViewChild('selectDetailedElement', { static: false })
  selectDetailedElement: ElementRef;

  @ViewChild('divContainCanvas', { static: false })
  divContainCanvas: ElementRef;

  @ViewChild('contentOutputInput', { static: false })
  contentOutputInput: ElementRef;

  @ViewChild('municipalCodeInput', { static: false })
  municipalCodeInput: ElementRef;

  @ViewChild('pageCountsInput', { static: false })
  pageCountsInput: ElementRef;

  @ViewChild('durationInput', { static: false })
  durationInput: ElementRef;

  @ViewChild('forecastParamInput', { static: false })
  forecastParamInput: ElementRef;

  @ViewChild('divPreview', { static: false })
  divPreview: ElementRef;

  @ViewChild('ldsRing', { static: false })
  ldsRing: ElementRef;

  @ViewChild('divNewsPages', { static: false })
  divNewsPages: ElementRef;
  //#endregion

  /**
   * save data success
   */
  @Output() saveDataSuccess = new EventEmitter<boolean>();

  constructor(
    private indexWordGroupService: IndexWordGroupService,
    private actionService: MenuActionService,
    private dialogService: DialogService,
    private newsContentService: NewsContentService,
    private newsContentDetailService: NewsContentDetailService,
    private weatherContentService: WeatherContentService,
    private weatherContentDetailService: WeatherContentDetailService,
    private municipalService: MunicipalService,
    private renderer: Renderer2,
    private toastService: ToastrService,
    private dataService: DataService,
    private drawService: DrawService,
    private readonly store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private commonService: CommonService,
    private executingService: ExecutingService
  ) {
    // subscribe action
    this.subscriptions.push(
      this.actionService.actionAdd.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.addContent();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.editContent();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          if (!this.contentDetailSelected) {
            this.deleteContent();
          } else if (this.contentDetailSelected && !this.isActiveNewsTab) {
            this.deleteContentDetail();
          }
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionChangeTemplate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.changeTemplate();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          if (this.contentSelected?.isEdit) {
            this.saveEditContent(this.isChangedNewsTab || this.isChangedWeatherTab);
          } else {
            this.saveBeforeLeave();
          }
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionSaveContentDetail.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.save();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionCreateOutputFile.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.createOutputFile();
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((contentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: contentState?.externalContentManagerState?.stateOfComponent.isChangeLayout,
            contents: contentState?.externalContentManagerState?.stateOfComponent.contents,
            indexWordGroups: contentState?.externalContentManagerState?.stateOfComponent.indexWordGroups,
            isActiveNewsTab: contentState?.externalContentManagerState?.stateOfComponent.isActiveNewsTab,
            isEnlargePreview: contentState?.externalContentManagerState?.stateOfComponent.isEnlargePreview,
            isChangeMunicipalCode: contentState?.externalContentManagerState?.stateOfComponent.isChangeMunicipalCode,
            contentSelected: contentState?.externalContentManagerState?.stateOfComponent.contentSelected,
            contentDetailSelected: contentState?.externalContentManagerState?.stateOfComponent.contentDetailSelected,
            areas: contentState?.externalContentManagerState?.stateOfComponent.areas,
            newsCurrentPage: contentState?.externalContentManagerState?.stateOfComponent.newsCurrentPage,
            areaDisabledOptions: contentState?.externalContentManagerState?.stateOfComponent.areaDisabledOptions,
            inputFocusCurrent: contentState?.externalContentManagerState?.stateOfComponent.inputFocusCurrent,
            contentCloneSelected: contentState?.externalContentManagerState?.stateOfComponent.contentCloneSelected,
            isChangedData: contentState?.externalContentManagerState?.stateOfComponent.isChangedData,
            isChangedNewsTab: contentState?.externalContentManagerState?.stateOfComponent.isChangedNewsTab,
            isChangedWeatherTab: contentState?.externalContentManagerState?.stateOfComponent.isChangedWeatherTab
          };
        })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((newsContent: any) => {
          this.newsContentTabStateOfComponent = {
            isChangeTab: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.isChangeTab,
            contents: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.contents,
            contentSelected: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.contentSelected,
            contentDetailSelected: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.contentDetailSelected,
            areas: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.areas,
            newsCurrentPage: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.newsCurrentPage,
            areaDisabledOptions: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.areaDisabledOptions,
            inputFocusCurrent: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.inputFocusCurrent,
            contentCloneSelected: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.contentCloneSelected
          };
        })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((weatherContent: any) => {
          this.weatherContentTabStateOfComponent = {
            isChangeTab: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.isChangeTab,
            indexWordGroups: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.indexWordGroups,
            contents: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.contents,
            isChangeMunicipalCode: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.isChangeMunicipalCode,
            contentSelected: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.contentSelected,
            contentDetailSelected: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.contentDetailSelected,
            areas: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.areas,
            areaDisabledOptions: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.areaDisabledOptions,
            inputFocusCurrent: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.inputFocusCurrent,
            contentCloneSelected: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.contentCloneSelected
          };
        })
    );
    this.pausePreviewSubject.subscribe(() => {
      this.isPlayPreview = false;
    });
    this.commonObject = this.commonService.getCommonObject();
  }

  /**
   * on init
   */
  async ngOnInit() {
    if (!this.stateOfComponent?.isChangeLayout) {
      this.executingService.executing();
      await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
      this.executingService.executed();
      this.isActiveNewsTab = this.commonObject?.isActiveNewsTab === undefined ? true : this.commonObject.isActiveNewsTab;
      this.isActiveNewsTab ? this.chooseNewsTab() : this.chooseWeatherTab();
    } else {
      this.handleSetDataForComponentAfterChangeLayout();
    }
  }

  /**
   * on destroy
   */
  ngOnDestroy() {
    this.pausePreviewSubject.next();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.highlightAreaPreviewSubject.unsubscribe();
    this.pausePreviewSubject.unsubscribe();
    this.resetTimerPreviewSubject.unsubscribe();
    this.store.dispatch(
      new SaveExternalContentManagerStateAction({
        isChangeLayout: true,
        contents: this.contents,
        indexWordGroups: this.indexWordGroups,
        isActiveNewsTab: this.isActiveNewsTab,
        isEnlargePreview: this.isEnlargePreview,
        isChangeMunicipalCode: this.isChangeMunicipalCode,
        contentSelected: this.contentSelected,
        contentDetailSelected: this.contentDetailSelected,
        areas: this.areas,
        newsCurrentPage: this.newsCurrentPage,
        areaDisabledOptions: this.areaDisabledOptions,
        inputFocusCurrent: this.inputFocusCurrent,
        contentCloneSelected: this.contentCloneSelected,
        isChangedData: this.isChangedData,
        isChangedNewsTab: this.isChangedNewsTab,
        isChangedWeatherTab: this.isChangedWeatherTab
      })
    );
    delete this.commonObject.isActiveNewsTab;
  }

  /**
   * set data for component after change layout
   */
  private handleSetDataForComponentAfterChangeLayout() {
    this.contents = this.stateOfComponent.contents;
    this.indexWordGroups = this.stateOfComponent.indexWordGroups;
    this.isActiveNewsTab = this.stateOfComponent.isActiveNewsTab;
    this.isEnlargePreview = this.stateOfComponent.isEnlargePreview;
    this.isChangeMunicipalCode = this.stateOfComponent.isChangeMunicipalCode;
    this.contentSelected = this.stateOfComponent.contentSelected;
    this.contentDetailSelected = this.stateOfComponent.contentDetailSelected;
    this.areas = this.stateOfComponent.areas;
    this.newsCurrentPage = this.stateOfComponent.newsCurrentPage;
    this.areaDisabledOptions = this.stateOfComponent.areaDisabledOptions;
    this.inputFocusCurrent = this.stateOfComponent.inputFocusCurrent;
    this.contentCloneSelected = this.stateOfComponent.contentCloneSelected;
    this.isChangedData = this.stateOfComponent.isChangedData;
    this.isChangedNewsTab = this.stateOfComponent.isChangedNewsTab;
    this.isChangedWeatherTab = this.stateOfComponent.isChangedWeatherTab;
    this.isPlayPreview = false;
    this.newsTotalPages = this.isActiveNewsTab && this.contentSelected ? this.contentSelected.pageCounts : 1;
    this.changeDetectorRef.detectChanges();
    this.handleDrawContent();
    this.commonObject.isActiveNewsTab = this.isActiveNewsTab;
    Helper.saveMainStateAction(this.store, this.commonObject);
  }

  /**
   * dispatch content tab data to store (news / weather)
   */
  private dispatchContentTabDataToStore() {
    if (this.isActiveNewsTab) {
      this.store.dispatch(
        new SaveWeatherContentTabStateAction({
          indexWordGroups: this.indexWordGroups,
          contents: this.contents,
          isChangeMunicipalCode: this.isChangeMunicipalCode,
          contentSelected: this.contentSelected,
          contentDetailSelected: this.contentDetailSelected,
          areas: this.areas,
          areaDisabledOptions: this.areaDisabledOptions,
          inputFocusCurrent: this.inputFocusCurrent,
          contentCloneSelected: this.contentCloneSelected
        })
      );
    } else {
      this.store.dispatch(
        new SaveNewsContentTabStateAction({
          contents: this.contents,
          contentSelected: this.contentSelected,
          contentDetailSelected: this.contentDetailSelected,
          areas: this.areas,
          newsCurrentPage: this.newsCurrentPage,
          areaDisabledOptions: this.areaDisabledOptions,
          inputFocusCurrent: this.inputFocusCurrent,
          contentCloneSelected: this.contentCloneSelected
        })
      );
    }
  }

  /**
   * handle set data for news content tab from store
   */
  private handleSetDataForNewsTabAfterChangeTab() {
    this.contents = this.newsContentTabStateOfComponent.contents;
    this.contentSelected = this.newsContentTabStateOfComponent.contentSelected;
    this.contentDetailSelected = this.newsContentTabStateOfComponent.contentDetailSelected;
    this.areas = this.newsContentTabStateOfComponent.areas;
    this.newsCurrentPage = this.newsContentTabStateOfComponent.newsCurrentPage;
    this.areaDisabledOptions = this.newsContentTabStateOfComponent.areaDisabledOptions;
    this.inputFocusCurrent = this.newsContentTabStateOfComponent.inputFocusCurrent;
    this.contentCloneSelected = this.newsContentTabStateOfComponent.contentCloneSelected;
    this.isPlayPreview = false;
    this.newsTotalPages = this.contentSelected ? this.contentSelected.pageCounts : 1;
    this.handleDrawContent();
  }

  /**
   * handle set data for weather content tab from store
   */
  private handleSetDataForWeatherTabAfterChangeTab() {
    this.indexWordGroups = this.weatherContentTabStateOfComponent.indexWordGroups;
    this.contents = this.weatherContentTabStateOfComponent.contents;
    this.isChangeMunicipalCode = this.weatherContentTabStateOfComponent.isChangeMunicipalCode;
    this.contentSelected = this.weatherContentTabStateOfComponent.contentSelected;
    this.contentDetailSelected = this.weatherContentTabStateOfComponent.contentDetailSelected;
    this.areas = this.weatherContentTabStateOfComponent.areas;
    this.areaDisabledOptions = this.weatherContentTabStateOfComponent.areaDisabledOptions;
    this.inputFocusCurrent = this.weatherContentTabStateOfComponent.inputFocusCurrent;
    this.contentCloneSelected = this.weatherContentTabStateOfComponent.contentCloneSelected;
    this.newsTotalPages = 1;
    this.newsCurrentPage = 1;
    this.handleDrawContent();
  }

  /**
   * handle draw content after change tab
   */
  private handleDrawContent() {
    if (this.contentSelected?.template) {
      Helper.createCanvasTemplate(this.contentSelected.template, this.divContainCanvas, this.renderer);
      this.createAllCanvasAreaTemplate(this.contentSelected.template);
      this.calculateScaleTransformCanvas(this.contentSelected.template);
      this.drawPreviewForContent(this.contentSelected, false);
      this.highlightAreaPreviewSubject.next();
    }
  }

  /**
   * choose news tab
   */
  public chooseNewsTab() {
    if (this.divContainCanvas) {
      Helper.clearNodeChild(this.divContainCanvas.nativeElement);
    }
    this.isActiveNewsTab = true;
    this.commonObject.isActiveNewsTab = this.isActiveNewsTab;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.dispatchContentTabDataToStore();
    this.contents = [];
    this.contentSelected = undefined;
    this.contentDetailSelected = undefined;
    this.newsCurrentPage = 1;
    if (!this.newsContentTabStateOfComponent.isChangeTab) {
      this.store.dispatch(
        new SaveNewsContentTabStateAction({
          isChangeTab: true
        })
      );
      // get data news contents
      this.newsContentService.getNewsContents().subscribe(
        newsContentsDataResponse => {
          this.contents = Helper.convertDataNewsContents(newsContentsDataResponse);
          this.selectContent(this.contents[0]);
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      this.handleSetDataForNewsTabAfterChangeTab();
    }
  }

  /**
   * choose weather tab
   */
  public chooseWeatherTab() {
    if (this.divContainCanvas) {
      Helper.clearNodeChild(this.divContainCanvas.nativeElement);
    }
    this.isActiveNewsTab = false;
    this.commonObject.isActiveNewsTab = this.isActiveNewsTab;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.dispatchContentTabDataToStore();
    this.contents = [];
    this.contentSelected = undefined;
    this.contentDetailSelected = undefined;
    this.pausePreviewSubject.next();
    if (!this.weatherContentTabStateOfComponent.isChangeTab) {
      this.store.dispatch(
        new SaveWeatherContentTabStateAction({
          isChangeTab: true
        })
      );
      // get data index word groups
      this.indexWordGroupService.getIndexWordGroups().subscribe(
        iwGroupsDataResponse => {
          this.indexWordGroups = iwGroupsDataResponse;
          // get data weather contents
          this.weatherContentService.getWeatherContents().subscribe(
            weatherContentsDataResponse => {
              this.contents = Helper.convertDataWeatherContents(weatherContentsDataResponse);
              this.selectContent(this.contents[0]);
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      this.handleSetDataForWeatherTabAfterChangeTab();
    }
  }

  /**
   * select content
   *
   * @param content
   * @param isChangeTemplate
   */
  public selectContent(content: any, isChangeTemplate?: boolean) {
    if (!content || this.contentSelected?.isEdit) {
      return;
    }
    if (this.isPlayPreview) {
      this.pausePreviewSubject.next();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING)
        },
        autoFocus: false
      });
      return;
    }
    this.pausePreviewSubject.next();
    this.contentDetailSelected = undefined;
    this.changeDetectorRef.detectChanges();
    Helper.clearNodeChild(this.divContainCanvas.nativeElement);
    this.contentSelected = content;
    this.newsCurrentPage = 1;
    this.newsTotalPages = this.isActiveNewsTab ? this.contentSelected.pageCounts : 1;
    if (!this.contentSelected.template) {
      return;
    }
    if (this.isActiveNewsTab) {
      this.newsContentService.getNewsContentWithFullDataTemplateById(content.id).subscribe(
        newsContentDataResponse => {
          this.setDataForContent(newsContentDataResponse, isChangeTemplate);
          this.newsContentDetailService.getNewsContentDetailsByContentId(this.contentSelected.id).subscribe(
            newsContentDetailsDataResponse => {
              this.contentSelected.newsContentDetails = newsContentDetailsDataResponse.map(
                Helper.convertDataNewsContentDetailFromServer.bind(Helper)
              );
              this.drawPreviewForContent(this.contentSelected, false);
              this.areaDisabledOptions = this.contentSelected.newsContentDetails.map(newsContentDetail => newsContentDetail.area);
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      this.weatherContentService.getWeatherContentWithFullDataTemplateById(this.contentSelected.id).subscribe(
        weatherContentDataResponse => {
          this.setDataForContent(weatherContentDataResponse, isChangeTemplate);
          this.weatherContentDetailService.getWeatherContentDetailsByContentId(this.contentSelected.id).subscribe(
            weatherContentDetailsDataResponse => {
              this.contentSelected.weatherContentDetails = weatherContentDetailsDataResponse.map(
                Helper.convertDataWeatherContentDetailFromServer.bind(Helper)
              );
              this.drawPreviewForContent(this.contentSelected, false);
              this.areaDisabledOptions = this.contentSelected.weatherContentDetails.map(weatherContentDetail => weatherContentDetail.area);
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    }
  }

  /**
   * select content detail
   *
   * @param contentDetail
   * @returns
   */
  public selectContentDetail(contentDetail: any) {
    if (this.contentDetailSelected == contentDetail) {
      return;
    }
    this.contentDetailSelected = contentDetail;
    this.highlightAreaPreviewSubject.next();
  }

  /**
   * set data for content
   *
   * @param contentDataResponse
   * @param isChangeTemplate
   */
  private setDataForContent(contentDataResponse: any, isChangeTemplate: boolean) {
    const index = this.contents.findIndex(content => content.id == this.contentSelected.id);
    if (index == -1) {
      return;
    }
    this.contents[index] = this.isActiveNewsTab
      ? Helper.convertDataNewsContentFromServer(contentDataResponse)
      : Helper.convertDataWeatherContentFromServer(contentDataResponse);
    this.contentSelected = this.contents[index];
    if (this.contents[index].template == null) {
      return;
    }
    this.areas = this.isActiveNewsTab
      ? Helper.getAllAreaTemplate(this.contents[index].template).filter(area => !area.isFix && area.checkTypeTextArea())
      : Helper.getAllAreaTemplate(this.contents[index].template).filter(area => !area.isFix);
    Helper.createCanvasTemplate(this.contents[index].template, this.divContainCanvas, this.renderer);
    this.createAllCanvasAreaTemplate(this.contents[index].template);
    this.calculateScaleTransformCanvas(this.contents[index].template);
    if (isChangeTemplate && this.areas.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Warning',
          text: 'Template of the selected content does not contain link area.'
        },
        autoFocus: false
      });
    }
  }

  /**
   * add content
   */
  private addContent() {
    let error = undefined;
    if (this.contentSelected?.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.contentDetailSelected = undefined;
    Helper.clearNodeChild(this.divContainCanvas.nativeElement);
    if (this.isActiveNewsTab) {
      this.pausePreviewSubject.next();
      this.newsCurrentPage = 1;
      const news = new NewsContent('', 5, 5);
      this.contents.push(news);
    } else {
      const weather = new WeatherContent('', '');
      this.contents.push(weather);
    }
    this.contentSelected = this.contents[this.contents.length - 1];
    this.contentSelected.isEdit = true;
    this.isChangedData = true;
    if (this.contents.length > 9) {
      this.changeDetectorRef.detectChanges();
      this.scrollIntoViewSelected(this.selectElement, this.contents.length - 1);
    }
  }

  /**
   * edit content
   */
  private editContent() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.contentDetailSelected = undefined;
    this.highlightAreaPreviewSubject.next();
    this.contentCloneSelected = _.cloneDeep(this.contentSelected);
    this.contentSelected.isEdit = true;
    this.isChangedData = true;
  }

  /**
   * save edit content
   *
   * @param isSaveAllContentDetail
   */
  public saveEditContent(isSaveAllContentDetail: boolean) {
    let error = this.validateContent();
    if (error) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: 'Error',
            text: error
          },
          autoFocus: false
        },
        () => {
          this.inputFocusCurrent.focus();
        }
      );
      return;
    }
    this.contentSelected.contentOutputFileName = this.contentSelected.contentOutputFileName.trim();
    if (this.isActiveNewsTab) {
      if (!this.contentSelected.id) {
        this.newsContentService.addNewsContent(this.contentSelected).subscribe(
          newsContentDataResponse => {
            if (newsContentDataResponse.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: 'Error',
                  text: Helper.getErrorMessage(ErrorEnum.MAX_LENGTH, this.propertiesName.content, this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME)
                }
              });
              return;
            }
            this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataNewsContentFromServer(newsContentDataResponse));
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
      } else {
        this.newsContentService.editNewsContent(Helper.convertDataNewsContentFromClient(this.contentSelected)).subscribe(
          newsContentDataResponse => {
            if (newsContentDataResponse.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: 'Error',
                  text: Helper.getErrorMessage(ErrorEnum.MAX_LENGTH, this.propertiesName.content, this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME)
                }
              });
              return;
            }
            this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataNewsContentFromServer(newsContentDataResponse));
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
      }
    } else {
      this.contentSelected.municipalCode = this.contentSelected.municipalCode.trim();
      if (this.isChangeMunicipalCode) {
        const getMunicipalName = () => {
          return new Promise<void>(resolve => {
            this.municipalService.getMunicipalByCode(this.contentSelected.municipalCode).subscribe(data => {
              this.contentSelected.municipalName = data ? `${data.cityName}${data.municipalName}` : null;
              this.isChangeMunicipalCode = false;
              resolve();
            });
          });
        };
        getMunicipalName().then(() => {
          if (!this.contentSelected.id) {
            this.weatherContentService.addWeatherContent(this.contentSelected).subscribe(
              weatherContentDataResponse => {
                if (weatherContentDataResponse.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: 'Error',
                      text: Helper.getErrorMessage(
                        ErrorEnum.MAX_LENGTH,
                        this.propertiesName.content,
                        this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME
                      )
                    }
                  });
                  return;
                }
                this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataWeatherContentFromServer(weatherContentDataResponse));
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          } else {
            this.weatherContentService.editWeatherContent(Helper.convertDataWeatherContentFromClient(this.contentSelected)).subscribe(
              weatherContentDataResponse => {
                if (weatherContentDataResponse.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: 'Error',
                      text: Helper.getErrorMessage(
                        ErrorEnum.MAX_LENGTH,
                        this.propertiesName.content,
                        this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME
                      )
                    }
                  });
                  return;
                }
                this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataWeatherContentFromServer(weatherContentDataResponse));
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          }
        });
      } else {
        if (!this.contentSelected.id) {
          this.weatherContentService.addWeatherContent(this.contentSelected).subscribe(
            weatherContentDataResponse => {
              this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataWeatherContentFromServer(weatherContentDataResponse));
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        } else {
          this.weatherContentService.editWeatherContent(Helper.convertDataWeatherContentFromClient(this.contentSelected)).subscribe(
            weatherContentDataResponse => {
              this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataWeatherContentFromServer(weatherContentDataResponse));
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        }
      }
    }
  }

  /**
   * handle after add content
   *
   * @param contentDataResponse
   * @param isSaveAllContentDetail
   */
  private handleAfterEditContent(isSaveAllContentDetail: boolean, contentDataResponse?: any) {
    const index = this.contents.findIndex(content => content.id == this.contentSelected.id);
    if (index == -1) {
      return;
    }
    this.setContentDataAfterUpdate(this.contents[index], contentDataResponse);
    this.contentSelected = this.contents[index];
    this.newsTotalPages = this.isActiveNewsTab ? this.contentSelected.pageCounts : 1;
    this.contentSelected.isEdit = false;
    this.isChangedData =
      this.newsContentTabStateOfComponent?.contentSelected?.isEdit || this.weatherContentTabStateOfComponent?.contentSelected?.isEdit;
    if (isSaveAllContentDetail) {
      this.saveBeforeLeave();
    } else {
      this.saveDataSuccess.emit(true);
    }
  }

  /**
   * Set content data after update
   *
   * @param content
   * @param contentDataResponse
   */
  private setContentDataAfterUpdate(content: any, contentDataResponse: any): void {
    content.id = contentDataResponse.id;
    content.contentOutputFileName = contentDataResponse.contentOutputFileName;
    content.contentOutputFileNameEncode = contentDataResponse.contentOutputFileNameEncode;
    content.isEdit = contentDataResponse.isEdit;
    if (this.isActiveNewsTab) {
      content['duration'] = contentDataResponse['duration'];
      content['pageCounts'] = contentDataResponse['pageCounts'];
    } else {
      content['municipalCode'] = contentDataResponse['municipalCode'];
      content['municipalName'] = contentDataResponse['municipalName'];
    }
  }

  /**
   * validate content output file name
   *
   * @returns error
   */
  private validateContentOutputFileName() {
    if (this.contentSelected.contentOutputFileName.trim() == '') {
      this.inputFocusCurrent = this.contentOutputInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.EMPTY, this.propertiesName.contentOutputFileName);
    } else if (this.contentSelected.contentOutputFileName.length > this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME) {
      this.inputFocusCurrent = this.contentOutputInput.nativeElement;
      return Helper.getErrorMessage(
        ErrorEnum.MAX_LENGTH,
        this.propertiesName.contentOutputFileName,
        this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME
      );
    }
    return undefined;
  }

  /**
   * validate municipal code
   *
   * @returns error
   */
  private validateMunicipalCode() {
    if (this.contentSelected.municipalCode.trim() == '') {
      this.inputFocusCurrent = this.municipalCodeInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.EMPTY, this.propertiesName.municipalCode);
    } else if (this.contentSelected.municipalCode.length > this.MAX_LENGTH_MUNICIPAL_CODE) {
      this.inputFocusCurrent = this.municipalCodeInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.MAX_LENGTH, this.propertiesName.municipalCode, this.MAX_LENGTH_MUNICIPAL_CODE);
    }
    return undefined;
  }

  /**
   * validate page counts
   *
   * @returns error
   */
  private validatePageCounts() {
    if (this.contentSelected.pageCounts == null) {
      this.inputFocusCurrent = this.pageCountsInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.EMPTY, this.propertiesName.pageCounts);
    } else if (this.contentSelected.pageCounts < this.MIN_PAGE_COUNTS) {
      this.inputFocusCurrent = this.pageCountsInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.MIN, this.propertiesName.pageCounts, this.MIN_PAGE_COUNTS);
    } else if (this.contentSelected.pageCounts > this.MAX_PAGE_COUNTS) {
      this.inputFocusCurrent = this.pageCountsInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.MAX, this.propertiesName.pageCounts, this.MAX_PAGE_COUNTS);
    }
    return undefined;
  }

  /**
   * validate duration
   *
   * @returns error
   */
  private validateDuration() {
    if (this.contentSelected.pageCounts != 1 && this.contentSelected.duration == null) {
      this.inputFocusCurrent = this.durationInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.EMPTY, this.propertiesName.duration);
    } else if (this.contentSelected.duration != null && this.contentSelected.duration < this.MIN_DURATION) {
      this.inputFocusCurrent = this.durationInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.MIN, this.propertiesName.duration, this.MIN_DURATION);
    } else if (this.contentSelected.duration != null && this.contentSelected.duration > this.MAX_DURATION) {
      this.inputFocusCurrent = this.durationInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.MAX, this.propertiesName.duration, this.MAX_DURATION);
    }
    return undefined;
  }

  /**
   * validate content
   */
  private validateContent() {
    let error = this.validateContentOutputFileName();
    if (error) {
      return error;
    }
    if (this.isActiveNewsTab) {
      return this.validatePageCounts() || this.validateDuration();
    } else {
      return this.validateMunicipalCode();
    }
  }

  /**
   * cancel edit content
   */
  public cancelEditContent() {
    this.contentSelected.isEdit = false;
    this.isChangedData =
      this.newsContentTabStateOfComponent?.contentSelected?.isEdit || this.weatherContentTabStateOfComponent?.contentSelected?.isEdit;
    if (!this.contentSelected.id) {
      this.contents.pop();
      this.contentSelected = undefined;
      if (this.contents.length > 0) {
        this.selectContent(this.contents[0]);
        this.scrollIntoViewSelected(this.selectElement, 0);
      }
    } else {
      this.contentSelected.contentOutputFileName = this.contentCloneSelected['contentOutputFileName'];
      if (this.isActiveNewsTab) {
        this.contentSelected.pageCounts = this.contentCloneSelected['pageCounts'];
        this.contentSelected.duration = this.contentCloneSelected['duration'];
      } else {
        this.contentSelected.municipalCode = this.contentCloneSelected['municipalCode'];
        this.contentSelected.municipalName = this.contentCloneSelected['municipalName'];
      }
    }
    this.contentCloneSelected = undefined;
  }

  /**
   * delete content
   */
  private deleteContent() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete ${this.contentSelected.contentOutputFileName}?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        },
        autoFocus: false
      },
      result => {
        if (result) {
          if (this.isActiveNewsTab) {
            this.newsContentService.deleteNewsContent(this.contentSelected.id).subscribe(
              () => {
                this.handleAfterDeleteContent();
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          } else {
            this.weatherContentService.deleteWeatherContent(this.contentSelected.id).subscribe(
              () => {
                this.handleAfterDeleteContent();
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          }
        }
      }
    );
  }

  /**
   * delete content detail
   */
  private deleteContentDetail() {
    if (!this.contentDetailSelected) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete selected detailed content?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        },
        autoFocus: false
      },
      result => {
        if (result) {
          this.contentSelected.weatherContentDetails = this.contentSelected.weatherContentDetails.filter(
            weatherContentDetail => weatherContentDetail.uuid != this.contentDetailSelected.uuid
          );
          const areaDelete = Helper.getAllAreaTemplate(this.contentSelected.template).find(
            area => area.id == this.contentDetailSelected.area.id
          );
          if (areaDelete) {
            let ctx = areaDelete.canvas.getContext('2d');
            ctx.clearRect(0, 0, areaDelete.canvas.width, areaDelete.canvas.height);
            if (areaDelete.checkTypeTextArea()) {
              ctx.fillStyle = areaDelete.getArea().backgroundColor;
              ctx.fillRect(0, 0, areaDelete.canvas.width, areaDelete.canvas.height);
            }
          }
          this.contentDetailSelected =
            this.contentSelected.weatherContentDetails.length > 0 ? this.contentSelected.weatherContentDetails[0] : undefined;
          this.highlightAreaPreviewSubject.next();
          this.areaDisabledOptions = this.isActiveNewsTab
            ? this.contentSelected.newsContentDetails.map(newsContentDetail => newsContentDetail.area)
            : this.contentSelected.weatherContentDetails.map(weatherContentDetail => weatherContentDetail.area);
          this.isChangedWeatherTab = true;
          if (this.contentSelected.weatherContentDetails.length > 0) {
            this.scrollIntoViewSelected(this.selectDetailedElement, 0);
          }
        }
      }
    );
  }

  /**
   * handle after delete content
   */
  private handleAfterDeleteContent() {
    this.contents = this.contents.filter(content => content.id != this.contentSelected.id);
    this.contentSelected = undefined;
    if (this.divContainCanvas) {
      Helper.clearNodeChild(this.divContainCanvas);
    }
    this.selectContent(this.contents[0]);
    this.scrollIntoViewSelected(this.selectElement, 0);
  }

  /**
   * add weather content detail
   */
  public addWeatherContentDetail() {
    if (!this.contentSelected) {
      return;
    }
    if (this.areas.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: 'Template of the selected content does not contain link area.'
        },
        autoFocus: false
      });
      return;
    }
    if (this.contentSelected.weatherContentDetails.length >= this.areas.length) {
      return;
    }
    this.isChangedWeatherTab = true;
    this.contentSelected.weatherContentDetails.push(new WeatherContentDetail(null, null, this.contentSelected.id, null, null));
    if (this.contentSelected.weatherContentDetails.length > 16) {
      this.changeDetectorRef.detectChanges();
      this.scrollIntoViewSelected(this.selectDetailedElement, this.contentSelected.weatherContentDetails.length - 1);
    }
  }

  /**
   * change template
   */
  private changeTemplate() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected?.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogChangeTemplateForExternalContentComponent,
      {
        data: {
          template: this.contentSelected.template
        },
        autoFocus: false
      },
      result => {
        this.isChangedData = false;
        if (result) {
          if (this.contentSelected.template?.id == result.id) {
            return;
          }
          this.contentSelected.template = result.id ? result : null;
          if (this.isActiveNewsTab) {
            this.newsContentService.changeTemplateForNewsContent(Helper.convertDataNewsContentFromClient(this.contentSelected)).subscribe(
              () => {
                this.contentSelected.newsContentDetails = [];
                this.selectContent(this.contentSelected, true);
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          } else {
            this.weatherContentService
              .changeTemplateForWeatherContent(Helper.convertDataWeatherContentFromClient(this.contentSelected))
              .subscribe(
                () => {
                  this.contentSelected.weatherContentDetails = [];
                  this.selectContent(this.contentSelected, true);
                },
                error => {
                  this.handleErrorFromApi(error);
                }
              );
          }
        }
      }
    );
  }

  /**
   * change select area
   *
   * @param contentDetail
   * @param areaId
   */
  public changeSelectArea(contentDetail: any, areaId: any) {
    contentDetail.area = areaId ? this.areas.find(area => area.id == areaId) : null;
    contentDetail.source = null;
    this.dataService.sendData(['clearSelectBox', `selectBox${contentDetail.uuid}`]);
    if (this.isActiveNewsTab) {
      this.newsCurrentPage = 1;
      this.isChangedNewsTab = true;
    } else {
      contentDetail.forecastParam = null;
      contentDetail.indexWordGroup = null;
      this.isChangedWeatherTab = true;
    }
    this.highlightAreaPreviewSubject.next();
    this.areaDisabledOptions = this.isActiveNewsTab
      ? this.contentSelected.newsContentDetails.map(newsContentDetail => newsContentDetail.area)
      : this.contentSelected.weatherContentDetails.map(weatherContentDetail => weatherContentDetail.area);
    this.drawPreviewForContent(this.contentSelected, true);
  }

  /**
   * change select source
   *
   * @param contentDetail
   * @param sourceValue
   */
  public changeSelectSource(contentDetail: any, sourceValue: any) {
    contentDetail.source = sourceValue ?? null;
    if (this.isActiveNewsTab) {
      this.newsCurrentPage = 1;
      this.isChangedNewsTab = true;
      contentDetail.texts = null;
      this.drawPreviewForContent(this.contentSelected, true);
    } else {
      this.isChangedWeatherTab = true;
      if (contentDetail.source == WeatherContentSourceEnum.WEATHER_LOCATION) {
        contentDetail.forecastParam = null;
      }
      this.drawPreviewWhenWeatherDataChanged(contentDetail);
    }
  }

  /**
   * change select index word group
   *
   * @param contentDetail
   * @param iwGroupId
   */
  public changeSelectIndexWordGroup(contentDetail: any, iwGroupId: any) {
    contentDetail.indexWordGroup = iwGroupId ? this.indexWordGroups.find(iwg => iwg.id == iwGroupId) : null;
    this.isChangedWeatherTab = true;
    this.drawPreviewWhenWeatherDataChanged(contentDetail);
  }

  /**
   * draw preview when source is changed
   *
   * @param contentDetail
   */
  private drawPreviewWhenWeatherDataChanged(contentDetail: any) {
    if (contentDetail.area.checkTypeTextArea()) {
      contentDetail.area.text = null;
    } else {
      contentDetail.area.media = null;
    }
    const areaOnCanvas = Helper.getAllAreaTemplate(this.contentSelected.template).find(
      areaOfTemplate => areaOfTemplate.id == contentDetail.area.id
    );
    this.drawAreaOntoCanvas(areaOnCanvas);
  }

  /**
   * save content
   */
  private save() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    if (this.isActiveNewsTab) {
      if (this.contentSelected.newsContentDetails.length == 0) {
        return;
      }
      this.contentDetailSelected = undefined;
      this.highlightAreaPreviewSubject.next();
      this.pausePreviewSubject.next();
      this.newsContentDetailService.saveNewsContentDetail(this.contentSelected.newsContentDetails).subscribe(
        newsContentDetailsDataResponse => {
          this.contentSelected.newsContentDetails = newsContentDetailsDataResponse.map(
            Helper.convertDataNewsContentDetailFromServer.bind(Helper)
          );
          this.newsCurrentPage = 1;
          this.isChangedNewsTab = false;
          this.handleAfterSave();
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      if (this.contentSelected.weatherContentDetails.length == 0) {
        return;
      }
      if (
        !this.contentSelected.weatherContentDetails.every(weatherContentDetail => {
          let errMess = undefined;
          if (
            weatherContentDetail.area &&
            (weatherContentDetail.forecastParam != undefined || weatherContentDetail.forecastParam != null)
          ) {
            if (weatherContentDetail.forecastParam < this.MIN_FORECAST_PARAM) {
              errMess = Helper.getErrorMessage(ErrorEnum.MIN, this.propertiesName.forecastParam, this.MIN_FORECAST_PARAM);
              this.inputFocusCurrent = this.forecastParamInput.nativeElement;
            } else if (weatherContentDetail.forecastParam > this.MAX_FORECAST_PARAM) {
              errMess = Helper.getErrorMessage(ErrorEnum.MAX, this.propertiesName.forecastParam, this.MAX_FORECAST_PARAM);
              this.inputFocusCurrent = this.forecastParamInput.nativeElement;
            }
          }
          if (errMess) {
            this.dialogService.showDialog(
              DialogMessageComponent,
              {
                data: {
                  title: 'Error',
                  text: errMess
                },
                autoFocus: false
              },
              () => {
                this.inputFocusCurrent.focus();
              }
            );
            return false;
          }
          return true;
        })
      ) {
        return;
      }
      this.contentDetailSelected = undefined;
      this.highlightAreaPreviewSubject.next();
      this.weatherContentDetailService
        .saveWeatherContentDetail(this.contentSelected.weatherContentDetails, this.contentSelected.id)
        .subscribe(
          weatherContentDetailsDataResponse => {
            this.contentSelected.weatherContentDetails = weatherContentDetailsDataResponse.map(
              Helper.convertDataWeatherContentDetailFromServer.bind(Helper)
            );
            this.isChangedWeatherTab = false;
            this.handleAfterSave();
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
    }
  }

  /**
   * save before leave
   */
  private saveBeforeLeave() {
    if (!this.contentSelected || this.contentSelected.isEdit) {
      return;
    }
    if (this.isActiveNewsTab) {
      if (this.contentSelected.newsContentDetails.length == 0) {
        return;
      }
      this.newsContentDetailService.saveNewsContentDetail(this.contentSelected.newsContentDetails).subscribe(
        () => {
          this.isChangedNewsTab = false;
          this.saveDataSuccess.emit(true);
          this.toastService.success('Saved successfully.', '');
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      if (
        !this.contentSelected.weatherContentDetails.every(weatherContentDetail => {
          let errMess = undefined;
          if (weatherContentDetail.area && weatherContentDetail.forecastParam) {
            if (weatherContentDetail.forecastParam < this.MIN_FORECAST_PARAM) {
              errMess = Helper.getErrorMessage(ErrorEnum.MIN, this.propertiesName.forecastParam, this.MIN_FORECAST_PARAM);
              this.inputFocusCurrent = this.forecastParamInput.nativeElement;
            } else if (weatherContentDetail.forecastParam > this.MAX_FORECAST_PARAM) {
              errMess = Helper.getErrorMessage(ErrorEnum.MAX, this.propertiesName.forecastParam, this.MAX_FORECAST_PARAM);
              this.inputFocusCurrent = this.forecastParamInput.nativeElement;
            }
          }
          if (errMess) {
            this.dialogService.showDialog(
              DialogMessageComponent,
              {
                data: {
                  title: 'Error',
                  text: errMess
                },
                autoFocus: false
              },
              () => {
                this.inputFocusCurrent.focus();
              }
            );
            return false;
          }
          return true;
        })
      ) {
        return;
      }
      this.weatherContentDetailService
        .saveWeatherContentDetail(this.contentSelected.weatherContentDetails, this.contentSelected.id)
        .subscribe(
          () => {
            this.isChangedWeatherTab = false;
            this.saveDataSuccess.emit(true);
            this.toastService.success('Saved successfully.', '');
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
    }
  }

  /**
   * handle after save
   */
  private handleAfterSave() {
    this.saveDataSuccess.emit(true);
    this.toastService.success('Saved successfully.', '');
    this.drawPreviewForContent(this.contentSelected, true);
  }

  /**
   * create output file
   */
  private createOutputFile() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    if (this.isActiveNewsTab) {
      this.newsContentService.createOutputFile(Helper.convertFullDataNewsContentFromClient(this.contentSelected)).subscribe(
        () => {
          this.toastService.success('Create output file successfully.', '');
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      this.weatherContentService.createOutputFile(Helper.convertFullDataWeatherContentFromClient(this.contentSelected)).subscribe(
        () => {
          this.toastService.success('Create output file successfully.', '');
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    }
  }

  /**
   * change municipal code for weather content by focus out
   *
   * @param event
   */
  public changeMunicipalCodeFocusOut(event: any) {
    if (!this.isChangeMunicipalCode || event.relatedTarget?.id == 'btn-edit-save' || event.relatedTarget?.id == 'btn-edit-cancel') {
      return;
    }
    this.municipalService.getMunicipalByCode(this.contentSelected.municipalCode).subscribe(data => {
      this.contentSelected.municipalName = data ? `${data.cityName}${data.municipalName}` : null;
      this.isChangeMunicipalCode = false;
    });
  }

  /**
   * change municipal code by enter key
   *
   * @param event
   */
  public changeMunicipalCodeEnterKey(event: any) {
    if (!this.isChangeMunicipalCode || !event) {
      return;
    }
    // key enter
    if (event.keyCode == this.ENTER_KEY_CODE) {
      this.municipalService.getMunicipalByCode(this.contentSelected.municipalCode).subscribe(data => {
        this.contentSelected.municipalName = data ? `${data.cityName}${data.municipalName}` : null;
        this.isChangeMunicipalCode = false;
      });
    }
  }

  /**
   * enlarge preview
   */
  public enlargePreview() {
    this.isEnlargePreview = !this.isEnlargePreview;
    if (!this.contentSelected) {
      return;
    }
    if (this.contentSelected.template) {
      this.calculateScaleTransformCanvas(this.contentSelected.template);
    }
  }

  /**
   * play preview
   */
  public playPreview() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.isPlayPreview = true;
    if (this.newsCurrentPage == this.contentSelected.pageCounts) {
      this.newsCurrentPage = 1;
      this.drawLinkTextAtNewsContent();
    }
    if (!this.contentSelected.duration || this.contentSelected.pageCounts == 1) {
      return;
    }
    this.resetTimerPreviewSubject
      .pipe(
        startWith(0),
        switchMap(() => timer(this.contentSelected.duration * 1000, this.contentSelected.duration * 1000)),
        takeUntil(this.pausePreviewSubject)
      )
      .subscribe(() => {
        if (this.newsCurrentPage == this.contentSelected.pageCounts) {
          this.pausePreviewSubject.next();
          return;
        }
        this.newsCurrentPage++;
        this.drawLinkTextAtNewsContent();
      });
  }

  /**
   * pause preview
   */
  public pausePreview() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.pausePreviewSubject.next();
  }

  /**
   * reset preview
   */
  public resetPreview() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    if (this.newsCurrentPage == 1) {
      return;
    }
    this.newsCurrentPage = 1;
    this.drawLinkTextAtNewsContent();
    if (this.isPlayPreview) {
      this.pausePreviewSubject.next();
    }
  }

  /**
   * next preview
   */
  public nextPreview() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    if (this.newsCurrentPage == this.contentSelected.pageCounts) {
      return;
    }
    this.newsCurrentPage++;
    this.drawLinkTextAtNewsContent();
    if (this.isPlayPreview) {
      this.resetTimerPreviewSubject.next();
    }
  }

  /**
   * prev preview
   */
  public prevPreview() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    if (this.newsCurrentPage == 1) {
      return;
    }
    this.newsCurrentPage--;
    this.drawLinkTextAtNewsContent();
    if (this.isPlayPreview) {
      this.resetTimerPreviewSubject.next();
    }
  }

  /**
   * draw link text at news content
   */
  private drawLinkTextAtNewsContent() {
    let areas = Helper.getAllAreaTemplate(this.contentSelected.template).filter(area => area.checkTypeTextArea() && !area.isFix);
    Promise.all(
      areas.map(area => {
        this.drawTextAreaForContent(<TextArea>area);
      })
    );
  }

  /**
   * reload content data
   */
  public reloadContentData() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.contentDetailSelected = undefined;
    this.highlightAreaPreviewSubject.next();
    this.isReloadContentData = true;
    this.changeDetectorRef.detectChanges();
    this.ldsRing.nativeElement.style.top =
      (this.divPreview.nativeElement.clientHeight - this.ldsRing.nativeElement.clientHeight) / 2 + 'px';
    this.ldsRing.nativeElement.style.left = (this.divPreview.nativeElement.clientWidth - this.ldsRing.nativeElement.clientWidth) / 2 + 'px';
    if (this.isActiveNewsTab) {
      if (this.contentSelected.newsContentDetails.length == 0) {
        this.isReloadContentData = false;
        return;
      }
      this.newsContentDetailService.reloadNewsContentDetails(this.contentSelected.newsContentDetails).subscribe(
        newsContentDetailsDataResponse => {
          this.contentSelected.newsContentDetails = newsContentDetailsDataResponse.map(
            Helper.convertDataNewsContentDetailFromServer.bind(Helper)
          );
          this.newsCurrentPage = 1;
          this.drawPreviewForContent(this.contentSelected, true);
          this.isReloadContentData = false;
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      if (this.contentSelected.weatherContentDetails.length == 0) {
        this.isReloadContentData = false;
        return;
      }
      this.weatherContentDetailService.reloadWeatherContentDetails(this.contentSelected.weatherContentDetails).subscribe(
        weatherContentDetailsDataResponse => {
          this.contentSelected.weatherContentDetails = weatherContentDetailsDataResponse.map(
            Helper.convertDataWeatherContentDetailFromServer.bind(Helper)
          );
          this.drawPreviewForContent(this.contentSelected, true);
          this.isReloadContentData = false;
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    }
  }

  /**
   * create all canvas area template
   *
   * @param template
   */
  private createAllCanvasAreaTemplate(template: Template) {
    const areas = Helper.getAllAreaTemplate(template);
    areas.forEach(area => {
      this.createCanvasArea(area);
    });
  }

  /**
   * create canvas area
   *
   * @param area
   */
  private createCanvasArea(area: Area) {
    const canvas = this.renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.style.left = area.posX + 'px';
    canvas.style.top = area.posY + 'px';
    canvas.style.width = area.width + 'px';
    canvas.style.height = area.height + 'px';
    this.highlightAreaPreviewSubject.subscribe(() => {
      canvas.style.border = this.contentDetailSelected?.area && this.contentDetailSelected?.area.id == area.id ? '7px solid #fff' : 'none';
      canvas.style.outline = this.contentDetailSelected?.area && this.contentDetailSelected?.area.id == area.id ? '7px solid #000' : 'none';
    });
    canvas.width = area.width;
    canvas.height = area.height;
    this.renderer.appendChild(this.divContainCanvas.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * draw preview content
   *
   * @param content
   * @param isOnlyDrawLinkArea
   */
  private drawPreviewForContent(content: any, isOnlyDrawLinkArea: boolean) {
    const areas = isOnlyDrawLinkArea
      ? Helper.getAllAreaTemplate(content.template).filter(area => !area.isFix)
      : Helper.getAllAreaTemplate(content.template);
    Promise.all(
      areas.map(area => {
        this.drawAreaOntoCanvas(area);
      })
    );
  }

  /**
   * draw text area for content
   *
   * @param area
   */
  private drawTextAreaForContent(textArea: TextArea) {
    if (!textArea.isFix) {
      textArea.text = this.getTextDataForLinkText(textArea);
    }
    if (textArea.text) {
      this.drawService.drawAreaText(textArea);
    } else {
      let ctx = textArea.canvas.getContext('2d');
      ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
      ctx.fillStyle = textArea.backgroundColor;
      ctx.fillRect(0, 0, textArea.canvas.width, textArea.canvas.height);
    }
  }

  /**
   * draw picture area for content
   *
   * @param area
   */
  private drawPictureAreaForContent(pictureArea: PictureArea) {
    if (!this.isActiveNewsTab && !pictureArea.isFix) {
      pictureArea.media = this.getPictureDataForLinkPicture(pictureArea);
    }
    this.drawPictureArea(pictureArea);
  }

  /**
   * draw area onto canvas
   *
   * @param content
   * @param area
   * @returns
   */
  private drawAreaOntoCanvas(area: Area) {
    if (!area) {
      return;
    }
    if (area.checkTypeTextArea()) {
      this.drawTextAreaForContent(<TextArea>area);
    } else {
      this.drawPictureAreaForContent(<PictureArea>area);
    }
  }

  /**
   * set text data for link text
   *
   * @param textArea
   */
  private getTextDataForLinkText(textArea: TextArea) {
    let text = undefined;
    if (this.isActiveNewsTab) {
      const newsContentDetail = this.contentSelected.newsContentDetails.find(contentDetail => contentDetail.area?.id == textArea.id);
      if (newsContentDetail?.source != null && newsContentDetail.texts) {
        text = newsContentDetail.texts[this.newsCurrentPage - 1] ?? undefined;
      }
    } else {
      const weatherContentDetail = this.contentSelected.weatherContentDetails.find(contentDetail => contentDetail.area?.id == textArea.id);
      switch (weatherContentDetail?.source) {
        case WeatherContentSourceEnum.WEATHER_LOCATION:
          text = weatherContentDetail.area.text ?? undefined;
          break;
        case WeatherContentSourceEnum.DATE_TIME_MM_DD:
        case WeatherContentSourceEnum.DATE_TIME_HH:
        case WeatherContentSourceEnum.TEMP:
          if (weatherContentDetail.forecastParam) {
            text = weatherContentDetail.area.text ?? undefined;
          }
          break;
        default:
          break;
      }
    }
    return text;
  }

  /**
   * set text data for link text
   *
   * @param pictureArea
   */
  private getPictureDataForLinkPicture(pictureArea: PictureArea) {
    let media = undefined;
    const weatherContentDetail = this.contentSelected.weatherContentDetails.find(contentDetail => contentDetail.area?.id == pictureArea.id);
    if (weatherContentDetail?.source != null && weatherContentDetail?.forecastParam && weatherContentDetail?.indexWordGroup) {
      media = weatherContentDetail.area.media ?? undefined;
    }
    return media;
  }

  /**
   * draw fix picture area
   *
   * @param area
   * @returns
   */
  private drawPictureArea(area: PictureArea) {
    if (!area.media) {
      return;
    }
    let ctx = area.canvas.getContext('2d');
    ctx.clearRect(0, 0, area.width, area.height);
    let imageHtmlElement = document.createElement('img');
    imageHtmlElement.src = area.media.url;
    let pattern = ctx.createPattern(imageHtmlElement, 'no-repeat');
    ctx.fillStyle = pattern;
    const mediaPosition = Helper.coverMedia(area.canvas, area.media, area.objectFit);
    // listen event load
    imageHtmlElement.addEventListener('load', () => {
      // draw image
      if (area.objectFit == ObjectFitEnum.FILL) {
        ctx.drawImage(imageHtmlElement, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
      } else {
        ctx.drawImage(
          imageHtmlElement,
          mediaPosition.sX,
          mediaPosition.sY,
          mediaPosition.sWidth,
          mediaPosition.sHeight,
          mediaPosition.x,
          mediaPosition.y,
          mediaPosition.width,
          mediaPosition.height
        );
      }
    });
  }

  /**
   * calculate scale transform canvas
   *
   * @param template
   */
  private calculateScaleTransformCanvas(template: Template) {
    this.changeDetectorRef.detectChanges();
    const maxHeight = this.divPreview.nativeElement.clientHeight;
    const maxWidth = this.divPreview.nativeElement.clientWidth;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (template.width > maxWidth) {
      scaleTransform.scaleX = maxWidth / template.width;
    }
    if (template.height > maxHeight) {
      scaleTransform.scaleY = maxHeight / template.height;
    }
    const scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    this.renderer.setStyle(this.divContainCanvas.nativeElement, 'transform', 'scale(' + scale + ')');
    const realHeightTemplate = template.height * scale;
    const realWidthTemplate = template.width * scale;
    const height = (maxHeight - realHeightTemplate) / 2;
    const width = (maxWidth - realWidthTemplate) / 2;
    this.divContainCanvas.nativeElement.style.marginTop = height + 'px';
    this.divContainCanvas.nativeElement.style.marginLeft = width + 'px';
    if (this.isReloadContentData) {
      this.ldsRing.nativeElement.style.top = (maxHeight - this.ldsRing.nativeElement.clientHeight) / 2 + 'px';
      this.ldsRing.nativeElement.style.left = (maxWidth - this.ldsRing.nativeElement.clientWidth) / 2 + 'px';
    }
    if (this.isActiveNewsTab && this.divNewsPages) {
      this.divNewsPages.nativeElement.style.top = height + realHeightTemplate + 3 + 'px';
    }
  }

  /**
   * key down event
   *
   * @param e
   */
  @HostListener('document:keydown', ['$event'])
  keyDown(e) {
    if (e.keyCode in this.keyCodeMap) {
      this.keyCodeMap[e.keyCode] = true;
      // save shortcut
      if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[83]) {
        this.save();
      }
    }
  }

  /**
   * key up event
   * @param e
   */
  @HostListener('document:keyup', ['$event'])
  keyUp(e) {
    if (this.keyCodeMap[e.keyCode]) {
      this.keyCodeMap[e.keyCode] = false;
    }
  }

  /**
   * handle error from Api
   *
   * @param error
   */
  private handleErrorFromApi(error: any) {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: `Error`,
        text: `An error has occurred. Please try again.`
      }
    });
  }

  /**
   * on change municipal code input
   *
   * @param input
   */
  public onChangeMunicipalCodeInput(input: any) {
    this.isChangeMunicipalCode = true;
    const value = input.value;
    const numbers = value.replace(/[^0-9]/g, '');
    input.value = numbers;
    this.contentSelected.municipalCode = numbers;
  }

  /**
   * on change number input
   *
   * @param input
   * @param properties
   * @returns
   */
  public onChangeNumberInput(input: any, properties: any) {
    const value = input.value;
    const numbers = value.replace(/[^0-9]/g, '');
    input.value = numbers;
    properties = numbers;
  }

  /**
   * on change forecast param input
   *
   * @param input
   * @param properties
   */
  public onChangeForecastParamInput(input: any, weatherDetail: any) {
    const value = input.value;
    const numbers = value.replace(/[^0-9]/g, '');
    input.value = numbers;
    weatherDetail.forecastParam = numbers;
    this.drawPreviewWhenWeatherDataChanged(weatherDetail);
  }

  /**
   * scroll select row
   */
  private scrollIntoViewSelected(elementRef: ElementRef, index: number): void {
    _.get(elementRef, `nativeElement.children[${index}]`, elementRef)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }
}
