import { ContentActions, ContentStateActionEnum } from './content-action';
import { INewsContentTabState, IWeatherContentTabState } from './content-state';

const initialNewsContentTabState: INewsContentTabState = {
  isChangeTab: false,
  contents: undefined,
  contentSelected: undefined,
  contentDetailSelected: undefined,
  areas: undefined,
  newsCurrentPage: undefined,
  areaDisabledOptions: undefined,
  inputFocusCurrent: undefined,
  contentCloneSelected: undefined
};
const newsContentTabStartupState: any = {
  newsContentTabStateOfComponent: initialNewsContentTabState
};

export function handleNewsContentTabReducer(state: any, action: ContentActions) {
  switch (action.type) {
    case ContentStateActionEnum.SAVE_NEWS_CONTENT_TAB_STATE:
      return {
        ...state,
        newsContentTabStateOfComponent: {
          isChangeTab: action.payload.isChangeTab ? action.payload.isChangeTab : state.newsContentTabStateOfComponent.isChangeTab,
          contents: action.payload.contents,
          contentSelected: action.payload.contentSelected,
          contentDetailSelected: action.payload.contentDetailSelected,
          areas: action.payload.areas,
          newsCurrentPage: action.payload.newsCurrentPage,
          areaDisabledOptions: action.payload.areaDisabledOptions,
          inputFocusCurrent: action.payload.inputFocusCurrent,
          contentCloneSelected: action.payload.contentCloneSelected
        }
      };
    case ContentStateActionEnum.RESET_NEWS_CONTENT_TAB_STATE:
      return {
        ...state,
        newsContentTabStateOfComponent: initialNewsContentTabState
      };
    default:
      return state;
  }
}

export function newsContentTabReducer(state = newsContentTabStartupState, action: ContentActions) {
  return handleNewsContentTabReducer(state, action);
}

const initialWeatherContentTabState: IWeatherContentTabState = {
  isChangeTab: false,
  indexWordGroups: undefined,
  contents: undefined,
  isChangeMunicipalCode: false,
  contentSelected: undefined,
  contentDetailSelected: undefined,
  areas: undefined,
  areaDisabledOptions: undefined,
  inputFocusCurrent: undefined,
  contentCloneSelected: undefined
};
const weatherContentTabStartupState: any = {
  weatherContentTabStateOfComponent: initialWeatherContentTabState
};

export function handleWeatherContentTabReducer(state: any, action: ContentActions) {
  switch (action.type) {
    case ContentStateActionEnum.SAVE_WEATHER_CONTENT_TAB_STATE:
      return {
        ...state,
        weatherContentTabStateOfComponent: {
          isChangeTab: action.payload.isChangeTab ? action.payload.isChangeTab : state.weatherContentTabStateOfComponent.isChangeTab,
          indexWordGroups: action.payload.indexWordGroups,
          contents: action.payload.contents,
          isChangeMunicipalCode: action.payload.isChangeMunicipalCode,
          contentSelected: action.payload.contentSelected,
          contentDetailSelected: action.payload.contentDetailSelected,
          areas: action.payload.areas,
          areaDisabledOptions: action.payload.areaDisabledOptions,
          inputFocusCurrent: action.payload.inputFocusCurrent,
          contentCloneSelected: action.payload.contentCloneSelected
        }
      };
    case ContentStateActionEnum.RESET_WEATHER_CONTENT_TAB_STATE:
      return {
        ...state,
        weatherContentTabStateOfComponent: initialWeatherContentTabState
      };
    default:
      return state;
  }
}

export function weatherContentTabReducer(state = weatherContentTabStartupState, action: ContentActions) {
  return handleWeatherContentTabReducer(state, action);
}
